import React from 'react';
import './industry.css';
import banking8 from '../../../assests/industries/bankingAndFinance/banking8.webp'


import travel1 from '../../../assests/industries/travel/travel1.jpeg';
import gaming2 from '../../../assests/industries/gaming/gaming2.webp';
import estate1 from '../../../assests/industries/realEstate/estate1.webp';
import media2 from '../../../assests/industries/media/media2.webp';
import ecommerce from '../../../assests/industries/ecommerce/ecommerce.webp';
import employment from '../../../assests/industries/employment/employment.webp';
import VTEquation from '../../VTEquation/equation';
import banking15 from '../../../assests/industries/bankingAndFinance/banking15.jpeg';
import VT_Communication from '../../../assests/industries/communication/VT_Communication.jpg';
import VT_Healthcare from '../../../assests/industries/healthcare/VT_Healthcare.jpg'
// import '../industries.css';
// import BankingAndFinanceBody from '../banking/bankingBody';
// import Healthcare from '../healthcare/healthcare';
// import SocialCommunication from '../socialCommunication/socialCommunication';
// import TravelAndHospitality from '../travelAndHosplitality/travel';
// import Gaming from '../gaming/gaming';
// import RealEstate from '../realEstate/realEstate';
// import MediaAndEntertainment from '../mediaAndEntertainment/media';
// import Employment from '../employment/employment';
// import Ecommerce from '../ecommerce/ecommerce';
// import KnowMore from '../../knowAboutus/knowabout';
// import Footer from '../../footer/footer';
import healthcare6 from '../../../assests/industries/healthcare/healthcare6.jpg'

const IndustryPage=()=>{
    return(
        <div className="industryPage">
            <div className="industryPageContent container">
                <div className="industryPageTitle">
                    <h1>INDUSTRIES</h1>
                </div>
                <div className="row industryPageBody">
                    <div className="col-lg-6 col-md-8 col-sm-8 col-xs-6 bankingImage healthcareImage">
                        <span>
                            <img src={VT_Healthcare} alt="banking8"/>
                        </span>

                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-6 bankingContent healthcareContent">
                        <h4>Healthcare</h4>
                        <div >
                        <p>
                            Post pandemic, the health industry has seen a massive wave of innovation, investment, and new entrants from all industry verticals. It is experiencing a structural shift from volume-based to value-based care. And as part of this shift, healthcare providers and payers are looking to leverage digital technologies to enrich care quality. 
                            Virtuosway's healthcare solutions help companies reimagine a more accessible, equitable, and scalable health system to meet the ever-changing regulatory, financial, competitive, and customer service requirements.Incorporating avant-garde trends and technologies, our team has successfully developed feature-rich and powerful healthcare solutions to streamline information exchange between health practitioners and patients
                        </p>
                        <h5>Decentralized clinical Trials</h5>
                        <p>
                            The most economical and sensible solution for clinicians and researchers to connect patient for clinical trials and to develop clinical operation efficient tools
                        </p>
                        <p className='healthcarePngImage' >
                            <img src={healthcare6} alt="healthcare"/>

                        </p>
                            
                        </div>
                    </div>
                </div>
                <div className="row industryPageBodyPart2 ">
                    <div className="col-lg-6 col-md-12 col-xs-6 col-sm-12">
                        <div className="row">
                            <span>
                                <img src={banking15} alt="healthcare"/>
                            </span>

                        </div>
                        <div className="row industryPageBodyPart2Content">
                            <h4>Banking and finance</h4>
                            <div >
                            <p>The confluence of</p>
                            <p>
                                <ul>
                                    <li>ubiquitous smartphone use</li>
                                    <li>advances in AI, ML, cloud-based services, and cryptography</li>
                                    <li>new regulations in the financial world has created an environment where practically all areas of finance are being disrupted by the use of technology. </li>
                                </ul>
                            </p>
                            <p>
                                Virtuosway's global team of expert developers cater to the ever-evolving technical demands of the banking industry by providing cutting-edge software solutions to streamline banking and financial workflows with the current landscape. 
                                We work with our clients on their digital transformation journey to emulate operational excellence and economic efficiency.
                            </p>
                        </div>
                        </div>

                    </div>
                    {/* <div className="col-lg-1 col-md-1 col-sm-1 col-xs-0"></div> */}
                    <div className="col-lg-6 col-md-12 col-xs-6 col-sm-12">
                        <div className="row">
                            <span>
                                <img src={VT_Communication} alt="communication2"/>
                            </span>

                        </div>
                        <div className="row industryPageBodyPart2Content">
                            <h4>Social communication</h4>
                            <p>
                                Across the world, digital identities are growing to be increasingly indispensable for organizations of all natures - private companies, governmental bodies, and civil organizations - even more for the people and communities they serve.
                                At Virtuosway, we realize the importance of putting social communication at the crux of your data-driven business model to reap concrete business benefits. 
                                Our team has continued to build well-executed social communication strategies and products for our clients to help them effectively and efficiently leverage communication responsibly and securely - resulting in increased brand integrity, efficiency, and sales. 
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="row industryPageBodyPart2 ">
                <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                        <div className="row">
                            <span>
                                <img src={travel1} alt="travel1"/>
                            </span>
                        </div>
                        <div className="row">
                            <h4>Travel and hospitality</h4>
                            <div>
                                <p>
                                    The COVID-19 pandemic has accelerated and redirected the global travel and hospitality industry for the next decade. 
                                    In this changing landscape, travel companies that prioritize customer experience can not only gain loyalty but also build resilience and future-proof their businesses. And that's precisely what our team of experts at Virtousway has been helping businesses do so far.                         
                                    As travel companies are still absorbing the pandemic's financial impact, they are also being forced to reassess their business strategies to enable positive customer experiences constantly.
                                </p>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-0"></div> 
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                        <div className="row">
                            <span>
                                <img src={gaming2} alt="gaming2"/>
                            </span>

                        </div>
                        <div className="row">
                            <h4>Gaming</h4>
                            <p>
                                Ranging from simple mobile games to immersive multiplayer experiences, gaming's popularity has exploded in recent years. The pandemic has accelerated customer acquisition and engagement by many folds. This is the ideal moment for game studios and gaming service providers to convert casual gamers into long-term customers.
                                Virtuosway's gaming solutions enable you to deliver engaging and highly immersive games to players worldwide, integrated with state-of-art technology and innovative monetization solutions. 
                            </p>
                           

                        </div>

                    </div>
                </div> */}
                {/* <div className="row industryPageBodyPart2 ">
                <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                        <div className="row">
                            <span>
                                <img src={estate1} alt="estate1"/>
                            </span>

                        </div>
                        <div className="row">
                            <h4>Real estate</h4>
                            <p>
                                The real estate industry is swiftly mobilizing itself and evolving all the time. Therefore, the sector demands solutions to automate all business operations and make real estate management a hassle-free task.According to a KPMG report, over 65% of the time a real estate company hires a tech company, it does so to enhance operational and speed efficiencies. From keeping the costs low to helping individuals make informed decisions, the benefits of implementing IT solutions in the real estate industry are one too many.At Virtousway, we specialize in creating bespoke end-to-end real estate software development - native or cross-platform - to streamline business operations, automate routine tasks, and ensure easy access to market information.
                            </p>

                        </div>

                    </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-0"></div> 
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                        <div className="row">
                            <span>
                                <img src={media2} alt="media2"/>
                            </span>

                        </div>
                        <div className="row">
                            <h4>Media and entertainment</h4>
                            <p>
                                Now, more than ever, the media and entertainment companies need efficient digitization to convert content into revenue. With the burgeoning use of gadgets, service providers should seek not only momentary gains but also zero in on their value proposition.The data-driven experts at Virtuosway work with companies that aspire to become early digitization adopters in this rapidly saturating market. Our expertise entails integrating digital and analytics tools to their venture, polishing corporate image, and making consignment hassle-free once everything is in place.
                            </p>

                        </div>

                    </div>
                </div> */}
                <div className="row industryPageBodyPart3 ">
                    <div className="col-lg-3 col-sm-12 col-md-12 col-xs-3">
                        <div className="row">
                            <span>
                                <img src={travel1} alt="travel1"/>
                            </span>
                        </div>
                        <div className="row">
                            <h4>Travel and hospitality</h4>
                            <div>
                                <p>
                                    <span>The COVID-19 pandemic has accelerated and redirected the global travel and hospitality industry for the next decade.</span>
                                    In this changing landscape, travel companies that prioritize customer experience can not only gain loyalty but also build resilience and future-proof their businesses. And that's precisely what our team of experts at Virtousway has been helping businesses do so far.                         
                                    As travel companies are still absorbing the pandemic's financial impact, they are also being forced to reassess their business strategies to enable positive customer experiences constantly.
                                </p>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-12 col-xs-3">
                        <div className="row">
                            <span>
                                <img src={gaming2} alt="gaming2"/>
                            </span>

                        </div>
                        <div className="row">
                            <h4>Gaming</h4>
                            <p>
                                Ranging from simple mobile games to immersive multiplayer experiences, gaming's popularity has exploded in recent years. The pandemic has accelerated customer acquisition and engagement by many folds. This is the ideal moment for game studios and gaming service providers to convert casual gamers into long-term customers.
                                Virtuosway's gaming solutions enable you to deliver engaging and highly immersive games to players worldwide, integrated with state-of-art technology and innovative monetization solutions. 
                            </p>
                           

                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-12 col-xs-3">
                        <div className="row">
                            <span>
                                <img src={estate1} alt="estate1"/>
                            </span>

                        </div>
                        <div className="row">
                            <h4>Real estate</h4>
                            <p>
                                The real estate industry is swiftly mobilizing itself and evolving all the time. Therefore, the sector demands solutions to automate all business operations and make real estate management a hassle-free task.From keeping the costs low to helping individuals make informed decisions, the benefits of implementing IT solutions in the real estate industry are one too many.At Virtousway, we specialize in creating bespoke end-to-end real estate software development - native or cross-platform - to streamline business operations, automate routine tasks, and ensure easy access to market information.
                            </p>

                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-12 col-xs-3">
                        <div className="row">
                            <span>
                                <img src={media2} alt="media2"/>
                            </span>

                        </div>
                        <div className="row">
                            <h4>Media and entertainment</h4>
                            <p>
                                Now, more than ever, the media and entertainment companies need efficient digitization to convert content into revenue. With the burgeoning use of gadgets, service providers should seek not only momentary gains but also zero in on their value proposition.The data-driven experts at Virtuosway work with companies that aspire to become early digitization adopters in this rapidly saturating market. Our expertise entails integrating digital and analytics tools to their venture, polishing corporate image, and making consignment hassle-free once everything is in place.
                            </p>

                        </div>

                    </div>

                </div>




                <div className="row industryPageBodyPart2 ">
                    <div className="col-lg-6 col-md-12 col-xs-6 col-sm-12">
                        <div className="row">
                            <span>
                                <img src={ecommerce} alt="healthcare"/>
                            </span>

                        </div>
                        <div className="row industryPageBodyPart2Content">
                            <h4>Ecommerce</h4>
                            <p>
                                E-commerce is transforming customers' shopping experience and has witnessed unprecedented growth, especially since the pandemic. The adoption of technology has made the sector more accessible and efficient than ever. 
                                Since our inception, we have been accumulating our e-commerce knowledge to create secure, highly interoperable e-commerce solutions for retailers big and small.The Virtuosway team is in the loop about the major challenges endured by the e-commerce industry and has exactly the right skills and resources to create unique solutions to address them.
                            </p>
                            
                        </div>

                    </div>
                    {/* <div className="col-lg-1 col-md-1 col-sm-1 col-xs-0"></div> */}
                    <div className="col-lg-6 col-md-12 col-xs-6 col-sm-12">
                        <div className="row">
                            <span>
                                <img src={employment} alt="communication2"/>
                            </span>

                        </div>
                        <div className="row industryPageBodyPart2Content">
                            <h4>Employment</h4>
                            <p>
                                Technology has changed recruitment faster than we realize - and it's a lot wider than just the invention of job searching apps. 
                                Digital platforms, automation, and other innovations are transforming the fundamental nature of work. Understanding these shifts and capitalizing on them is the way forward for both job seekers and providers. 
                                Professionals at Virtousway have vast industry-specific and first-hand knowledge of developing world-class employment solutions that help employers and candidates find better fits in the ever-changing hiring landscape. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='VTequation'><VTEquation/></div>

        </div>
        // <div className="IndustryPage">
        //     <div className="body2">
        //         <div className="bodyContent8 ">
        //             <div className="bodyBanking">
        //                 <div className="bankingBodyContent">
        //                     <div className='row'>
        //                         <h2>INDUSTRIES</h2>
        //                     </div>
        //                     <div className="row">
        //                         <span>Dramatically increase the success of your digital transformation. Whether developing net new solutions or scaling existing processes</span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div> 
        //     </div>
        //     <div className="industryBody container">
        //         <div><BankingAndFinanceBody/></div>
        //         <div><Healthcare/></div>
        //         <div><SocialCommunication/></div>
        //         <div><TravelAndHospitality/></div>
        //         <div><Gaming/></div>
        //         <div><RealEstate/></div>
        //         <div><MediaAndEntertainment/></div>
        //         <div><Employment/></div>
        //         <div><Ecommerce/></div>
        //     </div>
        //     <div><KnowMore/></div>
        //     {/* <div><Footer/></div> */}

        // </div>
        
    )
    
}
export default IndustryPage;