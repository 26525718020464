import React from 'react';
import './portfolio.css';
import image1 from '../../assests/portfolio/image1.png';
import image2 from '../../assests/portfolio/image2.png';
import image3 from '../../assests/portfolio/image3.png';
import image4 from '../../assests/portfolio/image4.png';
import worldmap from '../../assests/portfolio/worldmap.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import image5 from '../../assests/portfolio/image5.webp';

const Portfolio=()=>{
    return(
        <div className="Portfolio">
            <div className="row portfolioRow m-0">
                <div className="col-lg-8 col-md-8  portfolioImagesPart1">
                    <div className="row  m-0">
                        <div className=" col-md-6 client-projects">
                            <span>
                                <img src={image1} alt="image1" className="img-fluid"/>
                            </span>

                        </div>
                        <div className=" col-md-6 client-projects">
                            <span>
                                <img src={image2} alt="image2" className="img-fluid"/>
                            </span>
                        </div>

                    </div>
                    <div className="row  m-0">
                        <div className=" col-md-6 client-projects">
                            <span>
                                <img src={image3} alt="image3" className="img-fluid"/>
                            </span>

                        </div>
                        <div className=" col-md-6 client-projects">
                            <span>
                                <img src={image4} alt="image4" className="img-fluid"/>
                            </span>

                        </div>

                    </div>

                </div>
                <div className="col-lg-4 col-md-4  portfolioContent">
                    <div className="row worldmapText m-0">
                        <h2>
                            Creating amazing software platforms around the world
                        </h2>
                        {/* <p>
                            <span>PORTFOLIO<span className="portfolioRightArrow"><FontAwesomeIcon icon={faAngleRight}/></span></span>
                        </p> */}

                    </div>
                    <div className="row worldmapImage">
                        <span>
                            <img src={worldmap} alt="worldMap" className="img-fluid"/>

                        </span>
                    </div>
                </div>
            </div>
            <div className="row portfolioBottom">
                <div className="col-lg-6 col-md-6  portfolioBottomContent">
                    <h2>We've got it covered, We do it all , 24 x 7</h2>
                    <p>Our one stop,enterprise-wide web portal enables lifecycle management of current incidents and service requests across the enterprise.Users can report an incident, create a request,and monitor the status of pending requests.</p>
                    {/* <p>
                        <span>SUPPORT<span className="FARightArrow"><FontAwesomeIcon icon={faAngleRight}/></span></span>
                    </p> */}
                </div>
                <div className="col-lg-6 col-md-6 ">
                    <span>
                        <img   src={"Virtuosway_0005.jpg"} alt="image5" className="img-fluid"/>
                    </span>

                </div>

            </div>
            
        </div>
    )
}
export default Portfolio;