import React from 'react';
import './career.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import career1 from '../../assests/career/career1.webp'



const CareerBody=()=>{
    const careerPage=()=>{
        window.location.href=`/career/current-openings`
    }
    return(
        <div className='careerBody'>
            <div className="container">
                <circle></circle>
                <div className="careerBodyContent">
                    <div className="row careerTitle">
                        <span>CAREERS</span>
                    </div>
                    <div className="careerPageMissionStatement row">
                        <div className="col-lg-8 col-md-12 col-xs-8 col-sm-8">
                            <h3>Our mission is to improve the efficiency of your company by <span>providing high quality software systems.</span></h3>
                            <p>
                                We address your challenges with our professional consultations, optimized decisions, and cost-effective and reliable software solutions. We strive to exceed our customer expectation by providing high quality software services and products that provide strategic values to our clients. Our vision is to be known as a company that works hard to make the life of people easier, better and safer.
                            </p>
                            <p>
                                <button onClick={(e)=>{e.preventDefault();careerPage()}}>Our open positions<span className='faRight'><FontAwesomeIcon icon={faArrowRight}/></span></button>
                            </p>
                        </div>
                        {/* <div className="col-lg-1 col-md-1 col-sm-1 col-xs-0"></div> */}
                        <div className="careerPageImage col-lg-3 col-md-3 col-xs-3 col-sm-3">
                            <img src={career1}/>
                        </div>
                        

                    </div>

                </div>
                

            </div>
        </div>
    )
}

export default CareerBody;