import axios from "axios";
const API_KEY = "Bsm4VWTK3uO8VD7zNWNSyr1llqCfultx";

const axiosApiInstance = axios.create();

export const apis = {
  getJobsListing: () => {
    // return axiosApiInstance.get(`https://api.resumatorapi.com/v1/jobs/status/open?apikey=Bsm4VWTK3uO8VD7zNWNSyr1llqCfultx`)
    // return axiosApiInstance.get(`http://13.127.226.186:8000/api/getJobs`);
    // return axiosApiInstance.get(`https://43.204.149.201:8000/api/jobs`);

    return axiosApiInstance.get(`https://15.206.88.145:8001/api/jobs`);
  },
};
