import React, { useState } from "react";
import "./footer.css";
// import facebook from '../../assests/brandIcon/facebook.png';
// import linkedin from '../../assests/brandIcon/linkedin.png';
// import mail from '../../assests/brandIcon/mail.png'
import VTLOGO from "../../assests/VTLogo/VTLOGO.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationDot,
  faEnvelope,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import TermAndUse from "../policy/terms";
import GoogleMapReact from "google-map-react";
import Modal from "react-modal";

const Footer = () => {
  const [openMap, setOpenMap] = useState(false);
  const callAboutUs = () => {
    window.location.href = `/about-us`;
  };
  const openFacebook = () => {
    const newWindow = window.open(
      "https://www.facebook.com/virtuosway",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const openLinked = () => {
    const newWindow = window.open(
      "https://www.linkedin.com/company/80916327/",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const openInstagram = () => {
    const newWindow = window.open(
      "https://www.instagram.com/virtuosway/",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const privacyPolicyPage = () => {
    window.location.href = "/privacy-policy";
  };
  const TermAndUse = () => {
    window.location.href = "/TermAndUse";
  };
  const CurrentOpening = () => {
    window.location.href = "/career/current-openings";
  };
  const openMapModal = (e) => {
    e.preventDefault();
    setOpenMap(true);
  };

  return (
    <div className="footer">
      <div className="footerContent ">
        <div className="footerContentBody ">
          <div className=" row">
            {/* <div className="virtuoswayContactDetail col-lg-3">
                    <div className="row footerVtLogo">
                        <span>
                            <img src={VTLOGO} alt="VTLOGO"/>
                        </span>

                    </div>
                    <div className="row">
                        <span><FontAwesomeIcon icon={faLocationDot}/><span>Old Baneswor,Kathmandu</span></span>
                    </div>
                    <div className="row">
                        <span><FontAwesomeIcon icon={faPhone}/><span>01-4444444</span></span>
                    </div>
                    <div className="row">
                        <span><FontAwesomeIcon icon={faEnvelope}/><span>info@virtuosway.com</span></span>
                    </div>

                </div> */}
            <div className="footerExactContent ">
              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>

                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3">
                  <div className="row main">
                    <span>Company</span>
                  </div>
                  <div>
                    <div className="row">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          callAboutUs();
                        }}
                      >
                        About US
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <a href="/about-us?virtuosway-team">Our Team</a>
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <a href="/about-us?our-beliefs-in-action">
                          Our belief in action
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3">
                  <div className="row main">
                    <span>Career</span>
                  </div>
                  <div>
                    <div className="row">
                      <span>
                        <a href="/career/current-openings?current-openings-virtuosway">
                          Current Openings
                        </a>
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <a href="/about-us?our-values">Our Values</a>
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <a href="/career/current-openings?activities-virtuosway">
                          Activities
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3">
                  <div className="row main">
                    <span>Policy</span>
                  </div>
                  <div>
                    <div className="row">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          privacyPolicyPage();
                        }}
                      >
                        Privacy Policy
                      </span>
                    </div>
                    <div className="row">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          TermAndUse();
                        }}
                      >
                        Terms of Use{" "}
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <a href="/privacy-policy?virtuosway-cookies-policy">
                          Cookies Policy
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3">
                  <div className="row main">
                    <span>Contact</span>
                  </div>
                  <div>
                    <div className="row">
                      <span
                        onClick={(e) => {
                          openMapModal(e);
                        }}
                      >
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span>Old Baneshwor,Kathmandu</span>
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <FontAwesomeIcon icon={faPhone} />
                        <span>
                          {/* <a href="tel:+977-01-4481879">01-44818798</a> */}
                          <a href="tel:+977-01-5911890">01-5911890</a>
                        </span>
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <FontAwesomeIcon icon={faPhone} />
                        <span>
                          <a href="tel:+977-01-4581798">01-4581798</a>
                        </span>
                      </span>
                    </div>
                    <div className="row">
                      <span>
                        <a href="mailto:info@virtuosway.com">
                          <FontAwesomeIcon icon={faEnvelope} />
                          <span>info@virtuosway.com</span>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3">
                  <div className="row main">
                    <span>Follow us</span>
                  </div>
                  <div>
                    <div className="row footerImg">
                      <span>
                        <span>
                          <FontAwesomeIcon
                            icon={faFacebook}
                            onClick={(e) => {
                              e.preventDefault();
                              openFacebook();
                            }}
                          />
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon={faLinkedinIn}
                            onClick={(e) => {
                              e.preventDefault();
                              openLinked();
                            }}
                          />
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon={faInstagram}
                            onClick={(e) => {
                              e.preventDefault();
                              openInstagram();
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
          </div>
        </div>
      </div>
      <div className="FooterCopyRight ">
        <span>
          <span>
            <FontAwesomeIcon icon={faCopyright} />
          </span>{" "}
          VIRTUOSWAY SOFTWARE 2022
        </span>
      </div>
      <Modal
        isOpen={openMap}
        className="footer-map-modal"
        style={openMap ? { zIndex: "5" } : { zIndex: "0" }}
        ariaHideApp={false}
      >
        <div className="mapouter">
          <div>
            <iframe
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=virtuosway&t=&z=15&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="yes
                    "
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Footer;
