import React from 'react';
import './career.css';


const Value=()=>{
    return(
        <div className="valueCareerPage">
            <div className="contentValues">
                <div className="titleValue">
                    <h2>Great Work Requires Strong Support</h2>
                </div>
                <div className="ourValueContent">
                    <div className="row topRowOurValueContent">
                        <div>
                            <p>
                                At Virtuousway, you have an important role to play in the industrial dawn of innovation - building large-scale digital solutions to answer complex questions about the future.We take pride in our go-getter attitude that adds elements of challenge and fun to all our endeavors.As a company, we thrive by fostering excellence that drives creativity and innovation in everything we see and do.Our team members are proactive, trustworthy, and thoughtful doers. These are the qualities we look for when welcoming new talents. And we expect them to embody and respect these traits throughout their journey with us. 
                            </p>
                            
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                            <p><span>Think big</span></p>
                            <p>
                                At Virtuousway,we create and communicate a bold driection that inspires results.We think differently and look around corners for ways to serve customers.
                            </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                            <p><span>Challenge Yourself  </span></p>
                            <p>
                                We have a team of eager individuals with varying levels of savviness. So, we look ahead to learning and doing new things every day - challenging ourselves and preserving when going gets tough.
                            </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                            <p><span>Show Gratitude </span></p>
                            <p>
                                We believe in radiating positivity by practicing gratitude and recognizing each other's contributions every day. We empower each other by providing constructive feedback and helping out when 'stuck.'                            
                            </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                            <p><span>Maintain Work-Life Balance </span></p>
                            <p>
                                We believe in giving our best every time but also ensure we don't completely lose ourselves in it. We advocate devoting quality time and attention to our family, friends, and of course, ourselves.                             
                            </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                            <p><span>Be A Team Player </span></p>
                            <p>
                                Working well with others shows that you are committed to achieving both personal and organizational goals. Team players support each other during difficult times. Everyone has a role to play that is no less critical than your own. If a member of your team succeeds in the workplace, so do you. We work together towards a goal, we fail together, learn together and succeed together.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                            <p><span>Be Accountable</span></p>
                            <p>
                                We leave no stone unturned to stay true to the commitments we make to our team and our clients. Every single one of us aspires to deliver the highest-quality work on time since our accountability impacts everyone else's.                             
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Value;