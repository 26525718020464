import React from 'react';
import './admin.css';
import { Navigate } from 'react-router-dom';
import CareerData from '../career/data';


const AdminDashboard=()=>{
    let loginStatus=localStorage.getItem('login');
    if(loginStatus==="true"){
        const changeStatus=(num)=>{
            
            CareerData.data[num].status=JSON.stringify(!CareerData.data[num].status);
        
        }
        const tableContect=()=>{
            return CareerData.data.map((data,num)=>{
                return(
                    <tr key={num}>
                        <th>{num+1}</th>
                        <th><input type="checkbox" checked={data.status?"checked":""} onChange={(e)=>{e.preventDefault();changeStatus(num)}}/></th>
                        <th>{data.position}</th>
                        <th>{data.status?"shown":"hidden"}</th>
                    </tr>
                )
            })
        }
        return(
            <div className="adminDashboard container">
                <div className="adminDashboardContent">
                    <div className="topAdminDashboard">
                        <div className="adminDashboardWelcome">
                            <span>Admin dashboard</span>
                        </div>
                        <div className="adminDashboardLogout">
                            <span><button>logout</button></span>
                        </div>
                    </div>
                    <div className="tableAdminDashboard">
                        <div>
                        <table className="table">
                            <thead className="tableHeading">
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Check</th>
                                <th scope="col">Position</th>
                                <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                               {
                                tableContect()
                               }
                            </tbody>
                            </table>
                        </div>

                    </div>

                </div>
                
                
            </div>
        )

    }
    else{
        return <Navigate to="/admin-login-page" replace/>
    }
    
}
export default AdminDashboard;