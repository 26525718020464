import React, { useEffect, useState } from "react";
import "./career.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { apis } from "./apis/apiServices";
import { API_URL } from "./apis/constant";

const Opening = () => {
  useEffect(() => {
    apis.getJobsListing().then(({ data }) => {
      // console.log("<<<<----data--->>>", data);

      var sortedData = data.jobs.sort((a, b) => a.title.localeCompare(b.title));

      setJobListingData(sortedData);
    });
  }, []);
  const [jobListingData, setJobListingData] = useState([]);

  const showCareer = () => {
    const viewJob = (ID) => {
      // console.log("here", ID);
      // var jobTitle1 = positionOfJob.replaceAll('(','').replaceAll(')','').replaceAll('/','');
      // var jobTitle2 = jobTitle1.replace(/\s+/g, "-");
      const newWindow = window.open(
        // `${API_URL}/${barCodeID}/${jobTitle2}`,
        `${API_URL}/JD/${ID}`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    };
    // return jobListingData.data.map((data, i) => {
    return Object.values(jobListingData).map((data, i) => {
      return (
        <div
          className="dataOfCareer col-lg-3 col-md-5"
          key={i}
          onClick={(e) => {
            e.preventDefault();
            viewJob(data._id);
          }}
        >
          <div className="dataOfCareer1 ">
            <div>
              <div className="row position">
                <p>{data.title}</p>
              </div>
              <div className="row place">
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    viewJob(data._id);
                  }}
                >
                  <span>Apply</span>
                  <span>
                    <FontAwesomeIcon icon={faArrowRight} className="FAIcon" />
                  </span>
                </p>
              </div>
            </div>
            {/* <div className="viewJob">
                            <p onClick={(e)=>{e.preventDefault();viewJob(data.id)}}>
                                <span>Details</span>
                                <span><FontAwesomeIcon icon={faArrowRight} className="FAIcon"/></span>

                            </p>
                            
                        </div> */}
          </div>
        </div>
      );
    });
  };
  return (
    <div className="opening">
      <div className="openingTop">
        <h1>Current Openings</h1>
      </div>
      <div className="openingBody">
        <div className="row">{showCareer()}</div>
      </div>
    </div>
  );
};

export default Opening;
