import React from 'react';
import './persp.css';
//import persp from '../../assests/perspective/persp.webp';
import image6 from '../../assests/perspective/image6.webp'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const Perspective=()=>{
    const callSolutionPage=()=>{
        window.location.href=`/solution`
    }
    return(
        <div className="Persps row">
            <div className="PerspContent col-md-6">
                <h2>Delivering excellence,<br/> every time</h2>
                <div className="perspContentBody">
                    <p>
                        Virtuosway has extensive experience in creating high performing, digitally transformative and feature-packed native mobile applications for Android and iOS devices.
                    </p>
                    <p><span onClick={(e)=>{e.preventDefault();callSolutionPage()}}>SOLUTIONS<span className="rightArrow"><FontAwesomeIcon icon={faAngleRight}/></span></span>
                    </p>
                </div>
            </div>
            <div className="PerspImg col-md-6">
                <span>
                    <img src={"Virtuosway_00012.jpg"} alt="perspimage" className="img-fluid"/>
                </span>

            </div>
            

        </div>
    )
}
export default Perspective;