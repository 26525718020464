import React from 'react';
import './body.css';
import background from '../../assests/body/background.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import image9 from '../../assests/body/image9.webp'
import image10 from '../../assests/body/image10.webp';
import image5 from '../../assests/body/image14.webp';
import image12 from '../../assests/body/image13.webp'
import Carousel from 'react-bootstrap/Carousel';
import VideoPlayer from 'react-video-js-player';
import video1 from '../../assests/body/video1.webm'


const Body=()=>{
    return(
        <div className='bodySlider'>
            <Carousel >
                <Carousel.Item interval={5000}>
                    {/* <VideoPlayer src={video1} /> */}
                    {/* <img
                    className="d-block w-100 "
                    src={"Virtuosway_01.jpeg"}
                    alt="First slide"
                    />
                        */}
                    <div  className="d-block w-100 virtuosway-carousel-image-one "/>
             
                    
                    <Carousel.Caption>
                    <h3 className="FSL">Driven By Passion. Powered By Cutting Edge Technology.</h3>
                    {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                    {/* <img
                    className="d-block w-100 "
                    src={"Virtuosway_02.jpeg"}
                    alt="Second slide"
                    /> */}
                    <div  className="d-block w-100 virtuosway-carousel-image-two "/>
                    <Carousel.Caption>
                    <h3 className="FSL"></h3>
                    
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                    {/* <img
                    className="d-block w-100 "
                    src={"Virtuosway_03.jpeg"}
                    alt="Third slide"
                    /> */}
                     <div  className="d-block w-100 virtuosway-carousel-image-three "/>
                    <Carousel.Caption>
                    <h3 ></h3>
                   
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                    {/* <img
                    className="d-block w-100 "
                    src={"Virtuosway_0004.jpeg"}
                    alt="Third slide"
                    /> */}
                     <div  className="d-block w-100 virtuosway-carousel-image-four "/>
                    <Carousel.Caption>
                    <h3 ></h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            
            

        </div>







        // <div className="body1">
        //     <div className="bodyContent">
        //         <div className="contentOfBody">
                    
        //             <span>
        //                 <h2>Innovation with simplicity</h2>
        //             </span>
                    
        //             <p>We blend together technical advances, specific domain experience, and a sheer passion for excellence to create enterprise-grade solutions for organizations of all levels and caliber - from SMEs to global enterprises. </p>
        //             <p className="bodySeeMore"><span>See more <span className="FAIcon"><FontAwesomeIcon icon={faArrowRight} /></span></span></p>
        //         </div>
        //         <div>
        //             <span>
        //                 <img src={background} alt="girlBackground"/>
        //             </span>
        //         </div>
                
        //         {/*<span>INNOVATION WITH SIMPLICITY</span>
        //         <div className="BodyButton">
        //             <span><button>Book your free consultation</button></span>

        //         </div>*/}

        //     </div>
            
        // </div>
    )
  
       
}
export default Body;