import React,{useState} from 'react';
import './header.css';
//import logo from '../../assests/VTLogo/logo.png';
//import VTLOGO from '../../assests/VTLogo/VTLOGO.svg'
//import { HashLink as Link } from "react-router-hash-link";
import Vlogo from '../../assests/VTLogo/Vlogo.png'
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import {faTimes} from "@fortawesome/free-solid-svg-icons";
 import emailjs from "emailjs-com";
 import { sendMail } from './sendEmail';
// import { faFacebook,faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
// import banking from '../../assests/industries/bankingAndFinance/banking.webp';
// import image3 from '../../assests/industries/communication/image3.webp';
// import ecommerce from '../../assests/industries/ecommerce/ecommerce.webp';
// import employment from '../../assests/industries/employment/employment.webp'

const Header=()=>{
    const[openModal,setOpenModal]=useState(false);
    const[colorChange,setColorChange]=useState(false);
    const[clientData,setClientData]=useState([{fName:"",lName:"",email:"",phoneNumber:"",enquiry:""}])
   
    const ContactModal=()=>{
       // setOptionBanking(false);
        //setOptionSolution(false);
        setOpenModal(true);

    }
    const closeConactModal=()=>{
        setOpenModal(false);

    }
    const changeNavbarColor=()=>{
        if(window.scrollY>=80){
            setColorChange(true)
        }
        else{
            setColorChange(false);
        }
        }
    
    window.addEventListener('scroll',changeNavbarColor);
    
    const returnHome=()=>{
        window.location.href=`/`
    }
    const Industry=()=>{
        window.location.href=`/industries`
    }
    const Solution=()=>{
        window.location.href=`/solution`
    }
    // const BankingAndFinance=()=>{
    //     window.location.href=`/industry/banking-and-finance`
    // }
    // const Healthcare=()=>{
    //     window.location.href=`/industry/healthcare`
    // }
    // const SocialCommunication=()=>{
    //     window.location.href=`/industry/social-communication`
    // }
    // const TravelAndHospitality=()=>{
    //     window.location.href=`/industry/travel-and-hospitality`
    // }
    // const Gaming=()=>{
    //     window.location.href=`/industry/Gaming`
    // }
    // const realEstate=()=>{
    //     window.location.href=`/industry/real-estate`

    // }
    // const Media=()=>{
    //     window.location.href=`/industry/media-and-entertainment`
    // }
    // const Employ=()=>{
    //     window.location.href=`/industry/employment`
    // }
    // const ecommerce=()=>{
    //     window.location.href=`/industry/ecommerce`
    // }
    // const webDevelop=()=>{
    //     window.location.href=`/solution/web-development`
    // }
    // const mobileDevelop=()=>{
    //     window.location.href=`/solution/mobile-development`
    // }
    // const DataAnalytics=()=>{
    //     window.location.href=`/solution/data-analytics`
    // }
    // const blockchainTech=()=>{
    //     window.location.href=`/solution/blockchain-technologies`
    // }
    // const productDesign=()=>{
    //     window.location.href=`/solution/product-design`
    // }
    // const Quality=()=>{
    //     window.location.href=`/solution/QA`

    // }
    // const UI=()=>{
    //     window.location.href=`/solution/UI-UX`

    // }
    // const digitalInnovation=()=>{
    //     window.location.href=`/solution/digital-innovation`

    // }
    const TehncologyPages=()=>{
        window.location.href=`/technologies`

    }
    const CareerPage=()=>{
        window.location.href=`/career/current-openings`
    }
    const submitForm=(e)=>{
        if(clientData.fName && clientData.lName && clientData.email && clientData.phoneNumber && clientData.phoneNumber && clientData.enquiry ){
            emailjs.sendForm(
                'service_2i8pnkj',
                'template_3f798ke',
                e.target,
                'A55kZ3BT-Drr0_pJN'
            ).then(
                res=>{
                   if(res.status===200){
                       alert("You enquiry has been successfully submitted");
                       setClientData([{fName:"",lName:"",email:"",phoneNumber:"",enquiry:""}])
                   }
                }
            )
            .catch(err=>{alert("Unable to submit.Please try again later.")})
        }
        else{
            alert("please fill all the required informations")
        }
       
    }
   
   
    
    
    return(
        <div className="header" >
            <div className={openModal?"headerNotZIndex headerContent":"headerZIndex headerContent"}>
            <nav  className={colorChange?"headerBackgroundColor navbar navbar-expand-lg navbar-light":"bg-light navbar navbar-expand-lg navbar-light"}>
                <a className="navbar-brand headerLogo" href="/" >
                    <span onClick={(e)=>{e.preventDefault();returnHome()}}>
                        <span><img src={Vlogo} alt="logo"/></span>
                      
                    </span>
                </a>
                
                    <button 
                    className="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarNavAltMarkup" 
                    aria-controls="navbarNavAltMarkup" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> 
                
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav headerOptions">
                            <a className="nav-item nav-link" href="/" onClick={(e)=>{e.preventDefault();Industry()}}>Industries</a>
                          
                            <a className="nav-item nav-link"  href="/" onClick={(e)=>{e.preventDefault();Solution()}}>Solutions</a>
                            
                            <a className="nav-item nav-link " href="/"> 
                                <span onClick={(e)=>{e.preventDefault();TehncologyPages()}}>Technology</span>
                            </a>
                            <a className="nav-item nav-link " href="/"><span onClick={(e)=>{e.preventDefault();CareerPage()}}>Career</span></a>
                            <a className="nav-item nav-link " href="/" ><span onClick={(e)=>{e.preventDefault();ContactModal()}} >Contact</span></a>
                        </div>
                    </div>
                   
            </nav>
            </div>
            <Modal isOpen={openModal} className="modalContact"   ariaHideApp={false}>
                <div className="bodyContactUS">
                    <div className="headingModalContact">
                        <div><h1>Contact Us</h1></div>
                        <div><span ><FontAwesomeIcon icon={faTimes} onClick={(e)=>{e.preventDefault();closeConactModal()}}/></span></div>
                    </div>
                    <div className="bodyModalContact">
                        <p>Contact us to speak to an expert, request an assessment, or demo our solutions.</p>
                        <form onSubmit={(e)=>{e.preventDefault(); sendMail(e)}}>
                            <input type="text" placeholder='First Name *' name="fName" value={clientData.fName || ""} required onChange={(e)=>{e.preventDefault();setClientData({...clientData,fName:e.target.value})}}/>
                            <input type="text" placeholder='Last Name *'  name="LName" required value={clientData.lName || ""} onChange={(e)=>{e.preventDefault();setClientData({...clientData,lName:e.target.value})}}/>
                            <br/>
                            <input type="email" placeholder="Email *" name="email" required  value={clientData.email || ""} onChange={(e)=>{e.preventDefault();setClientData({...clientData,email:e.target.value})}}/>
                            <input type="text" placeholder='Phone Number *' name="phNumber" required value={clientData.phoneNumber || ""} onChange={(e)=>{e.preventDefault();setClientData({...clientData,phoneNumber:e.target.value})}}/>
                            <br/>
                            <textarea type="text" placeholder='Your Enquiry *' name="enquiry" required value={clientData.enquiry || ""} onChange={(e)=>{e.preventDefault();setClientData({...clientData,enquiry:e.target.value})}}/>
                            <br/>
                            <button >Submit</button>
                        </form>
                    </div>
                    {/* <div className="footerContactUs">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <div className="row countryContact"><span>Email</span></div>
                                <div className="row"><span>info@virtuosway.com</span></div>
                            </div>
                            
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <div className="row  countryContact">Follow Us On</div>
                                <div className="row"><span className="contactUsIcon"><FontAwesomeIcon icon={faFacebook}/></span><span className="contactUsIcon"><FontAwesomeIcon icon={faLinkedinIn}/></span></div>
                            </div>

                        </div>

                    </div> */}
                </div>

            </Modal>
            
           
        </div>
    )
}

export default Header;