import React from 'react';
import './technology.css';
//import tech from '../../assests/technology/tech.webp'
import react from '../../assests/technology/react.jpeg';
import reactNative from '../../assests/technology/reactNative.png';
import node from '../../assests/technology/node.jpeg';
import java from '../../assests/technology/java.png';
import kotlin from '../../assests/technology/kotlin.jpeg';
import swift from '../../assests/technology/swift.jpeg';
import flutter from '../../assests/technology/flutter.jpeg';
import php from '../../assests/technology/php.jpeg';
import mysql from '../../assests/technology/mysql.jpeg';
import python from '../../assests/technology/python.jpeg';
import c from '../../assests/technology/c++.svg';
import R from '../../assests/technology/R.png'
import Footer from '../footer/footer';
import KnowMore from '../knowAboutus/knowabout';
import VTEquation from '../VTEquation/equation';


const TechnologyPage=()=>{
    return(
        <div>
            <div className="body2">
            <div className="techPageBackgroundImage">
            <div className="bodyBanking">
                <div className="bankingBodyContent technologyPageTitle">
                        <div className='row'>
                            <span><h2>Technologies we use</h2></span>
                        </div>
                        <div className="row technologyBelowHeaderContent">
                            <span>We know that right web solutions will deliver result for your business.We can help with wide range of services to any business and startups</span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
            <div className="technologyContentBody container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={react} alt="reactLogo"/>
                                    <h5 className="card-title">
                                    
                                        React
                                    </h5>
                                    <p className="card-text">React is a free and open-source front-end Javascript library for building state-of-art user interfaces. Its adoption is growing like a blaze, thanks to its flexibility to create attractive and user-friendly solutions</p>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={reactNative} alt="reactNativeLogo"/>
                                    <h5 className="card-title">
                                        
                                        React Native
                                    </h5>
                                    <p className="card-text">React Native is an open-source JavaScript framework enabling native apps for Android, iOs, and web applications using the same code base. It is hailed as the 'next best thing' in programming.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={node} alt="NodeJSLogo"/>
                                    <h5 className="card-title">
                                        
                                        Node js
                                    </h5>
                                    <p className="card-text">Node.js is a javascript runtime built on Chrome's V8 Javascript engine and is used for non-blocking, event-driven servers. Especially useful for collecting data from multiple sources, it is designed with real-time, push-based architectures in mind. 
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={java} alt="JavaLogo" />
                                    <h5 className="card-title">
                                        
                                        Java
                                    </h5>
                                    <p className="card-text">The most widely used programming language worldwide, Java, is high-level, object-oriented, and class-based. Portability and platform independent nature are two main drivers behind its extensive popularity. It has loyal users across all industries.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={kotlin} alt="kotlinLogo" />
                                    <h5 className="card-title">
                                        
                                        Kotlin
                                    </h5>
                                    <p className="card-text">Kotlin is an open-sourced, statically typed programming language. Its growing worldwide adoption can be credited to its conciseness and transparency. As the chances of making code errors are profoundly reduced with Kotlin, it’s witnessing a steep growth in popularity. 

                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={swift} alt="swiftLogo" />
                                    <h5 className="card-title">
                                        
                                        Swift
                                    </h5>
                                    <p className="card-text">Swift is an industrial-friendly, intuitive programming language for iOS, iPadOS, macOS, and tvOS. As of 2021, 2.22 million apps are available for download in the app store - making Swift one of the most relevant and in-demand programming languages.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={flutter} alt="flutterLogo" />
                                    <h5 className="card-title">
                                        
                                        Flutter
                                    </h5>
                                    <p className="card-text">Flutter is Google's open-source framework for creating exquisite, natively compiled, multi-platform applications from a single codebase. Economical, versatile, and performant, Flutter is undoubtedly the most popular cross-platform mobile framework in 2021.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={php} alt="flutterLogo" />
                                    <h5 className="card-title">
                                        
                                        PHP
                                    </h5>
                                    <p className="card-text">PHP is a general-purpose scripting language used to develop interactive and dynamic websites. It was among the first server-side languages to be embedded into HTML. PHP is among the most popular programming languages in 2021.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={mysql} alt="flutterLogo" />
                                    <h5 className="card-title">
                                        
                                        MYSQL
                                    </h5>
                                    <p className="card-text">My SQL is a free and open-source software to tackle the database challenges of the next-generation web, communications, and cloud with security, scalability, uptime, and reliability. It's particularly used by companies with huge volumes of data and end-users.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={python} alt="pythonLogo" />
                                    <h5 className="card-title">
                                        
                                        PYTHON
                                    </h5>
                                    <p className="card-text">Python is an interpreted, general-purpose programming language designed with code readability in mind. Currently ranked among top programming languages, it became a two-time recipient of the prestigious TIOBE Programming Language of the Year award in 2021. 
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={c} alt="pythonLogo" />
                                    <h5 className="card-title">
                                        
                                        C++
                                    </h5>
                                    <p className="card-text">C++ is a powerful general-purpose programming language. It can be used to develop operating systems, browsers, games, and so on. C++ supports different ways of programming like procedural, object-oriented, functional, and so on. This makes C++ powerful as well as flexible.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-3 col">
                            <div className="card" >
                                <div className="card-body">
                                    <img src={R} alt="pythonLogo" />
                                    <h5 className="card-title">
                                        R Programming
                                    </h5>
                                    <p className="card-text">R is a programming language and software environment for statistical analysis, graphics representation and reporting. R was created by Ross Ihaka and Robert Gentleman at the University of Auckland, New Zealand, and is currently developed by the R Development Core Team. 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
            
        </div>
        {/* <div className='VTequation'><VTEquation/></div> */}
        {/* <div><KnowMore/></div>
        <div><Footer/></div> */}
        </div>
    )
}

export default TechnologyPage;