import React from 'react'
// import KnowMore from '../../knowAboutus/knowabout'
import './solutionPage.css';
import web2 from '../../../assests/solutions/webDevelopment/web2.webp';
import mobile2 from '../../../assests/solutions/mobiledevelopment/mobile2.webp';
import data1 from '../../../assests/solutions/dataAnalytics/data1.jpg';
import blockchain2 from '../../../assests/solutions/blockchainTech/blockchain2.jpg';
import design1 from '../../../assests/solutions/productDesign/design2.jpeg';
import QA1 from '../../../assests/solutions/QA/QA1.webp';
import UI1 from '../../../assests/solutions/UIUX/UI2.jpeg';
import digital1  from '../../../assests/solutions/digitalInnovation/digital1.webp';
import VTEquation from '../../VTEquation/equation';
import VT_Blockchain from '../../../assests/solutions/blockchainTech/VT_Blockchain.jpg';
import VT_UI from '../../../assests/solutions/UIUX/VT_UI.jpg'




// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//  import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
// import Footer from '../../footer/footer'
// import WebDevelopment from '../webDevelopment/web';
// import MobileDevelopment from '../mobileDevelopment/mdevelop';
// import DataAnalytics from '../dataAnalytics/data';
// import BlockChain from '../blockchainTech/blockchain';
// import ProductDesign from '../productDesign/product';
// import QA from '../QA/QA';
// import UIUX from '../UI/UI';
// import DigitalInnovation from '../digitalInnovations/digital';
// import { HashLink as Link } from "react-router-hash-link";

const Solution=()=>{
    return(
        <div className="solutionWholePage">
            <div className="solutionPageContent">
                <div className="solutionPageTitle">
                    <h1>SOLUTIONS</h1>
                </div>
                <div className="solutionPageBody container">
                    <div className="solutionPageBodyPart1 row">
                        <div className="col-lg-6 col-md-8 ">
                            <span>
                                <img src={web2} alt="web2"/>
                            </span>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 solutionContent solutionContentPartOne">
                            <h4>Web application development</h4>
                            <p>
                                The once crisp line between digital and physical realms is blurring more than ever. Now is the time for businesses to understand what the next era of computing entails; how it can transform businesses; and create new values as it unfolds. 
                                Our agile experts are ready to create bespoke web applications, platforms, and products to shape your business in correspondence with the demands of increasingly tech-savvy global consumers.. 
                                The once crisp line between digital and physical realms is blurring more than ever. Now is the time for businesses to understand what the next era of computing entails; how it can transform businesses; and create new values as it unfolds. 
                                Our agile experts are ready to create bespoke web applications, platforms, and products to shape your business in correspondence with the demands of increasingly tech-savvy global consumers.. 
                            </p>
                            <p className="changingColorPara">
                                <span>At Virtuosway, we offer end-to-end enterprise and web solutions using the latest technologies to create world-class B2B and B2C solutions.</span> With over 123 years of experience and a robust team of web experts, we will engineer high-quality yet budget-friendly web applications for your business. 
                                We bring revolution to reality. Shaping your business ideas into an innovative next-gen web-based app is what we do the best, as proven by deliverables across multiple industry verticals that are being used by millions. 
                                By partnering with Virtuosway to create made-to-order web applications, you can accelerate your business's user base, unlock new digital channels, raise conversion by many folds, and reach exciting business milestones. 
                            </p>

                        </div>
                    </div>
                    <div className="solutionPageBodyPart2 row">
                        <div className="col-lg-6    solutionContent solution2Content">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <span>
                                        <img src={mobile2} alt="web2"/>
                                    </span>
                                </div>
                            </div>
                            <div className="row vt-solution-items-description">
                                <h4>Mobile application </h4>
                                <p>
                                    Mobile application isn't just an incremental trend. It's changing the very nature of our society, culture, and business. Today, mobile applications are reshaping operation models, business models, and the marketplace at an often astonishing rate. Simply having a mobile presence is not going to be enough. 
                                </p>
                                <p className="changingColorPara">
                                    At Virtuosway, we create secure, intuitive, and scalable mobile applications that enhance operational efficiency, boost revenues, and create delightful customer experiences. 
                                    Designing visually appealing apps that are also user-friendly and interactive has always been at the forefront of our mobile app development service, as evident by our app portfolio. 
                                    Our Mobile Application team comprises accomplished experts and new talents, who together carry years of experience in the creation of high-performing, feature-packed native and cross-platform mobile applications that serve millions of users in both ios and android communities every single day. 
                                </p>
                                <p>
                                    As your trusted mobile app development partner, Virtuosway adapts to the best and latest mobile app planning and designing strategies - keeping in mind your vision and aspirations and materializing all the bells and whistles you have envisioned. 
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6  solutionContent solution2Content">
                            <div className="row">
                                <span>
                                    <img src={data1} alt="web2"/>
                                </span>
                            </div>
                            <div className="row vt-solution-items-description">
                                <h4>Data Analytics</h4>
                                <p>
                                    In the global economy, data is the new capital. Data-driven organizations display much higher resilience and can secure market positions as they are equipped to manage crises better and recover faster. 
                                    By keeping data analytics at the core, Virtuosway helps organizations make the best decisions efficiently. We enable businesses to manage their core business operations in the most economically-viable way and react on a day-to-day basis.
                                </p>
                                <p className="changingColorPara">
                                    Virtuosway is a data analytics solution provider with years of experience and a well-equipped team of data experts striving to help businesses deconstruct ever-growing volumes of data and thoroughly analyze the acquired information to unlock real business values. 
                                    We will capture, manage, analyze, and maintain data to help your business drive strategy and performance - from looking back into the past to evaluating what happened to forward-looking scenario predictive modeling. 
                                    At Virtuosway, we have the required cross-domain expertise to deliver data analytics services in crucial business areas like sales, financial risk, marketing, and supply chain analytics.
                                </p>
                            </div>

                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <span>
                                <img src={mobile2} alt="web2"/>
                            </span>

                        </div> */}
                    </div>
                    {/* <div className="solutionPageBodyPart1 row">
                        <div className="col-lg-6 col-md-8 col-sm-6 col-xs-6 solutionImage">
                            <span>
                                <img src={data1} alt="web2"/>
                            </span>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 solutionContent solutionContentPartOne">
                            <h4>Data Analytics</h4>
                            <p>
                                In the global economy, data is the new capital. Data-driven organizations display much higher resilience and can secure market positions as they are equipped to manage crises better and recover faster. 
                                By keeping data analytics at the core, Virtuosway helps organizations make the best decisions efficiently. We enable businesses to manage their core business operations in the most economically-viable way and react on a day-to-day basis.
                            </p>
                            <p className="changingColorPara">
                                Virtuosway is a data analytics solution provider with years of experience and a well-equipped team of data experts striving to help businesses deconstruct ever-growing volumes of data and thoroughly analyze the acquired information to unlock real business values. 
                                We will capture, manage, analyze, and maintain data to help your business drive strategy and performance - from looking back into the past to evaluating what happened to forward-looking scenario predictive modeling. 
                                At Virtuosway, we have the required cross-domain expertise to deliver data analytics services in crucial business areas like sales, financial risk, marketing, and supply chain analytics.
                            </p>
                        </div>
                    </div> */}
                    <div className="solutionPageBodyPart2 row">
                        <div className="col-lg-6 col-md-12  solutionContent solution2Content">
                            <div className="row">
                                <span>
                                    <img src={VT_Blockchain} alt="web2"/>
                                </span>
                            </div>
                            <div className="row vt-solution-items-description">
                                <h4>Blockchain technologies</h4>
                                <p>
                                    Blockchain is changing the very nature of doing business - helping companies reimagine how they can manage tangible and digital assets. From being a sophisticated and somewhat mysterious technology surrounding cryptocurrency to being promoted as the solution to issues ranging from supply-chain monitoring to fraud management to identity verification, the technology has evolved massively in the last decade.
                                    Explore the world of secure, interoperable, scalable, and decentralized applications with Virtuosway's end-to-end blockchain development and management services. 
                                    We have a proven track record of helping enterprises leverage the sheer power of blockchain technologies and solutions to attain next-level transparency and automation in their business processes.
                                </p>
                                <p className="changingColorPara">
                                    Virtuosway's team of blockchain experts design, formulate, and manage custom decentralized blockchain networks and combine the required blockchain expertise with cloud and web/mobile development capabilities to entirely address your business challenges. 
                                    Our blockchain solutions are customized to specific business use cases through feature integration, system modifications, and implementations. 
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12  solutionContent solution2Content">
                            <div className="row ">
                                <span>
                                    <img src={design1} alt="web2"/>
                                </span>

                            </div>
                            <div className="row vt-solution-items-description">
                                <h4>Product design</h4>
                                <p>
                                    Without efficient management and strategic vision, ideas and products based on them will quickly wither and die on the vine as the pressure from changing market landscape and competition outpaces a business's ability to grow and adapt.
                                </p>
                                <p className="changingColorPara">
                                    That's where Virtuosway's Product Design team comes in. Our expertise lies in helping your business succeed by helping you with all aspects of product strategy development and management. 
                                    We are a team of creative thinkers who use design and development to transform businesses, as we've done for a number of years now. 
                                </p>
                                <p>
                                    Combining the industry's best practices with our unique experience, our innovative team will take your product design from a preliminary concept to a final product - assisting your business to grow by creating products that deliver functional utility and a rich experience. 
                                    To transform your vision into reality, Virtuosway will assist your business from software product ideation to design, development, release, and maintenance.
                                    We will conceptualize and strategize the full roadmap to mitigate risks, keep costs under control, and deliver high-quality, scalable products tailored for your business needs.

                                </p>

                            </div>

                        </div>
                    </div>
                    {/* <div className="solutionPageBodyPart1 row">
                        <div className="col-lg-6 col-md-8 col-sm-6 col-xs-6 ">
                            <span>
                                <img src={design1} alt="web2"/>
                            </span>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 solutionContent solutionContentPartOne">
                            <h4>Product design</h4>
                            <p>
                                Without efficient management and strategic vision, ideas and products based on them will quickly wither and die on the vine as the pressure from changing market landscape and competition outpaces a business's ability to grow and adapt.
                            </p>
                            <p className="changingColorPara">
                                That's where Virtuosway's Product Design team comes in. Our expertise lies in helping your business succeed by helping you with all aspects of product strategy development and management. 
                                We are a team of creative thinkers who use design and development to transform businesses, as we've done for a number of years now. 
                            </p>
                            <p>
                                Combining the industry's best practices with our unique experience, our innovative team will take your product design from a preliminary concept to a final product - assisting your business to grow by creating products that deliver functional utility and a rich experience. 
                                To transform your vision into reality, Virtuosway will assist your business from software product ideation to design, development, release, and maintenance.
                                We will conceptualize and strategize the full roadmap to mitigate risks, keep costs under control, and deliver high-quality, scalable products tailored for your business needs.

                            </p>
                        </div>
                    </div> */}
                    <div className="solutionPageBodyPart2 row">
                        <div className="col-lg-6 col-md-8 col-sm-6 col-xs-6 solutionContent solution2Content QAAlignment">
                            <h4>Quality assurance</h4>
                            {/* <p>
                                The speed of new technology adoption, especially the IoT, AI, and blockchain, continues to drive significant change in software development. But companies eager to capitalize on these new advancements face a slew of quality delivery challenges.
                                Digitization is altering the expectations and demands towards quality - resulting in a score of strategic and organizational challenges.
                            </p> */}
                            <p >
                                Virtuosway's experienced QA experts offer world-class QA testing services for mobile and web applications - ensuring complete compliance and high-quality software every single time. Our team harnesses the power of innovative AI, IoT, and RPA techs to carry out advanced QA web & mobile testing. 
                                With over a decade-long experience under their belt, our QA team will perform functions spanning from creating full-cycle QA automated software for mobile and web applications to identifying and fixing bugs to ensure compliance with regulatory standards. 
                                Whether it's developing state-of-art QA testing solutions from scratch or building upon your existing QA testing solutions via modifications and integrations, Virtuosway will leave no stone unturned in ensuring quality and essence in every single deliverable.
                            </p>
                            <p className="changingColorPara">Our broad range of QA services comprises:</p>
                            <ul className="changingColorPara">
                                <li>Mobile Application Testing</li>
                                <li>Healthcare IT Testing</li>
                                <li>Automation Testing</li>
                                <li>Website Testing</li>
                                <li>Wireless Network Testing</li>
                                <li>Load and Performance Testing</li>
                                <li>Test Process Improvement </li>
                                <li>Telecom Testing </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 ">
                            <span>
                                <img src={QA1} alt="web2"/>
                            </span>

                        </div>
                    </div>
                    <div className="solutionPageBodyPart1 row vt-ui-ux-and-digital-row">
                        <div className="col-lg-6 col-md-12  UIImage solutionContent">
                            <div className="row ">
                                <span>
                                    <img src={VT_UI } alt="web2"/>
                                </span>
                            </div>
                            <div className="row vt-solution-items-description">
                                <h4>UI/UX</h4>
                                
                                <p>
                                    As with everything we do as designers at Virtuosway, UI/UX design principles pivot around the end-user - making visually appealing and user-friendly products for them. We strive to create experiences as streamlined and seamless as possible so that users can navigate through the site effortlessly. 
                                    We are a team of design experts with years of experience in enhancing businesses through smart design solutions. We strongly believe the key to developing a successful application is combining the functionality users demand with an intuitive design experience that makes it as simple as possible to use. 
                                </p>
                                <p >
                                    For us, a meticulously developed user experience is vital for the success of an application - whether the end goal is to keep the users engaged with your commercial app or maintain a smooth workflow for your employees on an enterprise platform.
                                    From concept to launch, Virtuosway will help you at every stage - bringing your ideas to life through scalable, futuristic, and sustainable designs. We will work with you to define, design,and create transformative user experiences across all platforms and brand's touchpoints. 

                                </p>
                                <p className="changingColorPara">Our core UI/UX Design Principles </p>
                                <ul className="changingColorPara">
                                    <li>User-Centric </li>
                                    <li>Clarity </li>
                                    <li>Minimal steps and actions per screen</li>
                                    <li>Usability</li>
                                    <li>Consistency </li>
                                    <li>Constructive Feedback</li>
                                    <li>Clear Next Step</li>
                                    <li>Complete Flow</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12  solutionContent solution2Content">
                            <div className="row">
                                <span>
                                    <img src={digital1} alt="web2"/>
                                </span>
                            </div>
                            <div className="row vt-solution-items-description">
                                <h4>Digital innovation</h4>
                                <p>
                                    Digital innovation is at the heart of every organization across all industry verticals, whether the goal is to streamline internal operations, find ways to engage new users, or bring new products and services to the market. But leading digital innovation is challenging - it's painstaking.
                                </p>
                                <p className="changingColorPara">
                                    At Virtuosway, digital innovation is a powerful combination of management consultancy and digital expertise. Our innovation experts constantly seek innovative, consumer-centric solutions that help our clients achieve their business targets. 
                                    We have a wide range of tools, services, and the necessary expertise across multiple disciplines and industries, which can be combined in ways that perfectly address your goals. We have been blending our extensive skills and experience with our digital knowledge and expertise to conjure one-of-a-kind and effective solutions for our clients. 
                                </p>
                                <p>
                                    From helping businesses simplify the way information is accessed and transactions are processed to digitizing back-office processes to enhancing existing products and services with digital components, we've got you covered. 
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    {/* <div className="solutionPageBodyPart2 row">
                        <div className="col-lg-6 col-md-8 col-sm-6 col-xs-6 solutionContent solution2Content">
                            <h4>Digital innovation</h4>
                            <p>
                                Digital innovation is at the heart of every organization across all industry verticals, whether the goal is to streamline internal operations, find ways to engage new users, or bring new products and services to the market. But leading digital innovation is challenging - it's painstaking.
                            </p>
                            <p className="changingColorPara">
                                At Virtuosway, digital innovation is a powerful combination of management consultancy and digital expertise. Our innovation experts constantly seek innovative, consumer-centric solutions that help our clients achieve their business targets. 
                                We have a wide range of tools, services, and the necessary expertise across multiple disciplines and industries, which can be combined in ways that perfectly address your goals. We have been blending our extensive skills and experience with our digital knowledge and expertise to conjure one-of-a-kind and effective solutions for our clients. 
                            </p>
                            <p>
                                From helping businesses simplify the way information is accessed and transactions are processed to digitizing back-office processes to enhancing existing products and services with digital components, we've got you covered. 
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <span>
                                <img src={digital1} alt="web2"/>
                            </span>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='VTequation'><VTEquation/></div>
        </div>
        // <div className="IndustryPage">
        //     <div className="body2">
        //         <div className="bodyContent9 ">
        //             <div className="bodyBanking">
        //                 <div className="bankingBodyContent">
        //                     <div className='row'>
        //                         <h2>SOLUTIONS</h2>
        //                     </div>
        //                     <div className="row">
        //                         <span>
        //                             We know that right web solutions will deliver results for your business. We can help with wide range of services to any SME business & startups.
        //                         </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div> 
        //     </div>
        //     <div className="solutionBody container">
        //         <div className="row">
        //             <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
        //                 <div id="webDevelopmentApp"><WebDevelopment/></div>
        //                 <div id="mobileDevelopmentApp"><MobileDevelopment/></div>
        //                 <div id="dataAnalytic"><DataAnalytics/></div>
        //                 <div id="blockchain"><BlockChain/></div>
        //                 <div id="productDesign"><ProductDesign/></div>
        //                 <div id="QA"><QA/></div>
        //                 <div id="UIUX"><UIUX/></div>
        //                 <div id="digitalInnovation"><DigitalInnovation/></div>
        //             </div>
        //             <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        //                 <div className="solutionBody2">
        //                     <span className="category">CATEGORIES</span>
        //                     <div>
        //                         <ul>
        //                             <li><Link smooth to="#webDevelopmentApp"><span className="WebFA" ><FontAwesomeIcon icon={faAngleRight} /></span><span>Web application development</span></Link></li>
        //                             <li><Link smooth to="#mobileDevelopmentApp"><span className="WebFA"><FontAwesomeIcon icon={faAngleRight}/></span><span>Mobile application</span></Link></li>
        //                             <li><Link smooth to="#dataAnalytic"><span className="WebFA"><FontAwesomeIcon icon={faAngleRight}/></span><span>Data analytics</span></Link></li>
        //                             <li><Link smooth to="#blockchain"><span className="WebFA"><FontAwesomeIcon icon={faAngleRight}/></span><span>Blockchain technologies</span></Link></li>
        //                             <li><Link smooth to="#productDesign"><span className="WebFA"><FontAwesomeIcon icon={faAngleRight}/></span><span>Product design</span></Link></li>
        //                             <li><Link smooth to="#QA"><span className="WebFA"><FontAwesomeIcon icon={faAngleRight}/></span><span>Quality assurance</span></Link></li>
        //                             <li><Link smooth to="#UIUX"><span className="WebFA"><FontAwesomeIcon icon={faAngleRight}/></span><span>UI/UX</span></Link></li>
        //                             <li><Link smooth to="#digitalInnovation"><span className="WebFA"><FontAwesomeIcon icon={faAngleRight}/></span><span>Digital innovations</span></Link></li>

        //                         </ul>

        //                     </div>
                            

        //                 </div>
        //             </div>

        //         </div>
                
        //     </div>
        //     <div><KnowMore/></div>
        //     <div><Footer/></div>

        // </div>
    )
}

export default Solution;