
const CareerData = {
  data: [
    {
      id: 0,
      position: "Dev Lead",
      status: true,
      jobDescription: [
        "Work closely with senior stakeholders to understand business requirements and assist in translating these into technical requirements for the development team.",
        "Direct and guide the application development team in terms of design, coding, testing, research, and analysis",
        "Maintain high standards of software quality within the team by establishing good practices and habits.",
        "Writing testable, scalable, and efficient code, as well as leading code reviews.",
        "Minimize technical exposure and risk on projects.",
        "Coach and mentor teammates to provide technical leadership",
        "Create reports, manuals, and other documentation on the status, operation, and upkeep of software.",
        "Designing, developing, and customizing applications to fulfill business process and application needs.",
        "Monitor the project lifecycle to control the costs and schedules of lead projects.",
      ],
      jobRequirements: [
        "Undergraduate Degree preferable in Computers, Information technology or Engineering.",
        "Minimum 5+ years experience as a Software Developer.",
        "Prior experience in a technical leadership position.",
        "Experience with agile development methodologies including Kanban and Scrum.",
        "In depth knowledge and extensive experience with JavaScript Stack - NodeJS and React JS",
        "Comprehensive understanding & must have Expertise on writing unit, integration and automation test with Jest, React Testing library, Cypress",
        "Familiarity with Firestore database - Circle CI, Code review",
        "Understanding of React, React Native  and Node JS",
      ],
    },
    {
      id: 1,
      position: "Node JS",
      status: true,
      jobDescription: [
        "Creating and maintaining all server-side network components",
        "Collaborate with team members to scope, develop, implement, and document new features.",
        "Developing high-performance applications through the use of testable, reusable, and efficient code.",
        "Implementing effective security protocols, data protection safeguards, and storage solutions.",
        "Keeping up with the latest developments in Node.js development.",
        "Recommending and implementing process and technology enhancements.",
      ],
      jobRequirements: [
        "Undergraduate Degree preferable in Computers, Information technology or Engineering",
        "Proven work experience of 3+ years as a Back-end developer using Node Js",
        "In-depth understanding of the entire web development process (design, development and deployment)",
        "Familiarity with Firestore database",
        "Experienced with Circle CI, Code review",
        "Familiarity with unit testing",
      ],
    },
    {
      id: 2,
      position: "Project Manager",
      status: true,
      jobDescription: [
        "Planning and supervising initiatives inside an organization, from inception to completion.",
        "Plan and allocate project resources, create budgets, track progress, and keep stakeholders updated throughout the process.",
        "Defining project objectives, scope, roles, and responsibilities.",
        "Preparing a thorough project plan to schedule major project milestones, workstreams, and activities.",
        "Delegating project duties to personnel who are most suited to fulfill them.",
        "Monitor progress and make any modifications required.",
        "Communicating with executives or the board to keep the project on track to meet its objectives.",
        "Submitting project deliverables and ensuring that quality criteria are met.",
        "Coordination of people and systems to complete projects on schedule, within budget, and with the intended outputs matched with objectives.",
        "Managing client satisfaction during the project transition time.",
      ],
      jobRequirements: [
        "Masters/Bachelors completed in Computer Science",
        "3+ years as project manager for IT company",
        "Ability to lead project teams of various sizes and see them through to completion",
        "Strong understanding of formal project management methodologies.",
        "Able to complete projects in a timely manner",
        "Experience overseeing at least 2 successfully delivered projects.",
      ],
    },
    {
      id: 3,
      position: "QA ",
      status: true,
      jobDescription: [
        "Create detailed, comprehensive and well-structured test plans and test cases to identify problems.",
        "Estimate, prioritize, plan and coordinate testing activities.",
        "Identify, record, document thoroughly and track bugs.",
        "Analyze bugs and errors found during tests.",
        "Document results of tests for the software development team.",
        "Monitor debugging process results.",
        "Review requirements, specifications and technical design documents to provide timely and meaningful feedback.",
        "Recommend improvements in software to enhance user experience.",
        "Investigate the causes of non-conforming software and train users to implement solutions.",
        "Track quality assurance metrics, like defect densities and open defect counts.",
        "Motivate the development process for efficiency and performance.",
        "Works together with the software developer to enhance and improve programs.",
        "Liaise with internal teams (e.g., developers and product managers) to identify system requirements..",
        "Research and compare similar competitor products.",
        "Maintain updated knowledge of industry trends and advancements.",
      ],
      jobRequirements: [
        "Undergraduate Degree preferable in Computers, Information technology or Engineering.",
        "Should have at least 3+ years of software development / testing experience.",
        "Test cases design.",
        "Understanding of Non-Functional testing.",
        "Good analytical skills.",
        "Debugging skills required.",
        "Knowledge on writing automation test.",
      ],
    },
    {
      id: 4,
      position: "QA Lead",
      status: true,
      jobDescription: [
        "Create testing procedures by specifying quality metrics and test strategies.",
        "Evaluating services and products to ensure they meet quality standards.",
        "Create, execute, and manage test plans.",
        "Test automation and manual testing tools.",
        "Recognize the business logic of existing projects and instill it in team members.",
        "Instruct and mentor the quality assurance team.",
        "Assist in defining QA coding standards and frameworks, as well as the identification of emerging technologies.",
      ],
      jobRequirements: [
        "Undergraduate Degree preferable in Computers, Information technology or Engineering",
        "Should have at least 6+ years of software development / testing experience",
        "Test cases design",
        "Understanding of Non-Functional testing",
        "Good analytical skills.",
        "Debugging skills required.",
        "Knowledge on writing automation test.",
      ],
    },
    {
      id: 5,
      position: "React JS",
      status: true,
      jobDescription: [
        "Designing and developing user interface components utilizing well-known React.js processes (such as Flux or Redux).",
        "Profiling and improving front-end performance, as well as documenting front-end codebase. ",
        "Creating software solutions to meet project specifications, maintaining existing code, writing tests, and fixing bugs.",
        "Commitment to collaborative problem solving, sophisticated design, and delivering a high-quality product.",
        "Coordinate with the rest of the team who are working on various layers of infrastructure.",
        "Design and build modern-day UI components to improvise application performance.",
      ],
      jobRequirements: [
        "Undergraduate Degree preferable in Computers, Information technology or Engineering.",
        "Proven work experience of 3+ years as Front-end developer using React Js. ",
        "In-depth knowledge of JavaScript, CSS, HTML, and front-end languages.",
        "Experience with user interface design.",
        "Knowledge of performance testing frameworks including Mocha and Jest.",
        "Experience with browser-based debugging and performance testing software.",
        "Excellent troubleshooting skills.",
        "Familiarity with unit testing.",
      ],
    },
    {
      id: 6,
      position: "React Native",
      status: true,
      jobDescription: [
        "Developing high-performance mobile apps for the iOS and Android platforms.",
        "Create interchangeable front-end modules by writing reusable, effective, and scalable JavaScript code.",
        "Use clean, modern, and smooth animations and transitions to provide an excellent user experience.",
        "Coordinating with the teams in charge of other layers of the product infrastructure.",
        "Recognize the needs of your clients in order to build apps within the time and budget constraints.",
        "Maintain code and write automated tests to ensure the product is of the highest quality.",
        "Transition existing React web apps to React Native.",
      ],
      jobRequirements: [
        "Undergraduate Degree preferable in Computers, Information technology or Engineering.",
        "3+ years of building mobile applications using React Native.",
        "Experience building and consuming APIs to interact with other applications using REST or GraphQL.",
        "Experience in design and building mobile first UI.",
        "Rock solid at working with third-party dependencies and debugging dependency conflicts.",
        "Familiarity with native build tools, like XCode, Gradle, Android Studio, IntelliJ.",
        "Understanding of REST APIs, the document request model, and offline storage.",
      ],
    },
    {
      id: 7,
      position: "UI/UX designer",
      status: true,
      jobDescription: [
        "Collect and evaluate user requirements with the help of product managers and engineers.",
        "Creating and designing a complete UI/UX design approach for the business.",
        "Creating style guides, design systems, design patterns, and appealing user interfaces from specifications.",
        "In-depth understanding of HTML, CSS, JS, and CSS preprocessors such as SCSS..",
        "Must be able to change current front end framework components to meet UI designs.",
      ],
      jobRequirements: [
        "Undergraduate Degree in revelent field.",
        "At least 3+ years of experience in UI/UX Design.",
        "Experience on UI/UX Design for mobile apps is must.",
        "Should be able to work with product managers and mobile developers.",
        "Must have knowledge of working in an Agile/Scrum development process.",
        "Working experience with HTML, CSS preprocessor like Sass, Less or Stylus and web front end framework like bootstrap.",
        "Should have worked in responsive mobile first web apps.",
        "Strong written and verbal communication skills.",
      ],
    },
  ],
};

export default CareerData;