import React from 'react';
import './career.css';
import image1 from '../../assests/activities/image1.webp';
import image2 from '../../assests/activities/image2.webp';
import image3 from '../../assests/activities/image3.webp';
import image14 from '../../assests/activities/image14.jpeg';
import image5 from '../../assests/activities/image5.webp';
import image6 from '../../assests/activities/image6.webp';
import image7 from '../../assests/activities/image7.webp';
import image8 from '../../assests/activities/image8.webp';
import image12 from '../../assests/activities/image12.jpg';
import image11 from '../../assests/activities/image11.avif';
import image15 from '../../assests/activities/image15.jpg'
import image13 from '../../assests/activities/image13.jpg';
import Image_11 from '../../assests/activities/Image_11.jpg'

const Activities=()=>{
    return(
        <div className="activities">
            <div className="activitiesTitle">
                <h2>Our Activities</h2>
            </div>
            <div className="activitiesContent">
                <div className="row">
                    <div className="col-lg-3 col-md-4  IMAGE1">
                        <span>
                            <img src={Image_11} alt="image1"/>
                        </span>
                    </div>
                    <div className='col-lg-8 col-sm-8 col-md-8 col-xs-8'>
                        <div className="row">
                            <div className="col-lg-3 col-md-8  IMAGE2">
                                <span>
                                    <img src={image5} alt="image5"/>
                                </span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-0 "></div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 IMAGE3">
                                <span>
                                    <img src={image12} alt="image5"/>
                                </span>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-10 col-sm-3 col-xs-3 IMAGE2">
                                <span>
                                    <img src={image13} alt="image3"/>
                                </span>
                            </div>
                            <div className="col-lg-2 col-md-1 col-0 "></div>

                            <div className="col-lg-2 col-md-2  IMAGE4">
                                <span>
                                    <img src={image15} alt="image4"/>
                                </span>
                            </div>
                            <div className="col-lg-2 col-md-7 col-0 "></div>

                            <div className="col-lg-2 col-md-2  IMAGE4">
                                <span>
                                    <img src={image8} alt="image4"/>
                                </span>
                            </div>

                        </div>

                    </div>
                    
                    
                </div>

            </div>
        </div>
    )
}
export default Activities;