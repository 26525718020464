import React from 'react';
import './career.css';
import CareerBody from './careerBody';
import Value from './values';
import Activities from './activities';


const CareerPage=()=>{
    return(
        <div>
            <div><CareerBody/></div>
            <div><Activities/></div>
            <div> <Value/></div>
            
            
           

        </div>

    )
}

export default CareerPage;