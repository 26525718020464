import React,{useState} from 'react';
import { Navigate } from 'react-router-dom';
import './career.css';
import CareerData from './data';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com"


const ViewJob=()=>{
    let ID=localStorage.getItem('id');
    const[applicantDetail,setApplicantDetail]=useState([{name:"",email:"",phoneNumber:"",CV:"",linkedlinAddress:"",resumeLink:""}])
    
    if(ID!==null && ID!==undefined){
        let data=CareerData.data[ID];
        const toCareerPage=()=>{
            window.location.href="/career/current-openings"
        }
       const showJobDescription=()=>{
        return data.jobDescription.map((i,num)=>{
            return(
                <li key={num}>{i}</li>
            )

        })
        
       }
        const showJobRequirements = () => {
          return data.jobRequirements.map((i, num) => {
            return <li key={num}>{i}</li>;
          });
        };
       const addResume=()=>{
           document.getElementById('resumeUploadButton').click();

       }
       const submitResume=(e)=>{
            if (
              applicantDetail.name &&
              applicantDetail.email &&
              applicantDetail.phoneNumber &&
              applicantDetail.CV &&
              applicantDetail.linkedlinAddress &&
              applicantDetail.resumeLink
            ) {
              emailjs
                .sendForm(
                  "service_2i8pnkj",
                  "template_vpziyzq",
                  e.target,
                  "A55kZ3BT-Drr0_pJN"
                )
                .then((res) => {
                  if (res.status === 200) {
                    alert("Your application is submitted");
                    setApplicantDetail([
                      {
                        name: "",
                        email: "",
                        phoneNumber: "",
                        CV: "",
                        linkedlinAddress: "",
                        resumeLink:"",
                      },
                    ]);
                  }
                })
                .catch((err) => {
                  alert("Unable to submit.Please try again later.");
                });
            }
           
           
       }
       const vtCvUpload=(e)=>{
        if(e.target.files[0].name){
            document.getElementById("fileAddedInfo").innerHTML="File Added";
            setApplicantDetail({...applicantDetail,resume:e.target.files[0]})

        }
        else{
            document.getElementById("fileAddedInfo").innerHTML="Unable to receive file";
        }
        


       }
        return (
          <div className="ViewJobPage">
            <div className="body2">
              <div className="bodyContent11 ">
                <div className="bodyBanking bodyCareer">
                  <div className="bankingBodyContent">
                    <div className="row jobTitle">
                      <h2>{data.position}</h2>
                    </div>
                    <div className="row jobLocation">
                      <p>
                        <span>
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        <span>KTM, Nepal</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="viewJobBody container">
              <div className="viewJobBodyMenu">
                <p>
                  <span
                    className="toCareerPage"
                    onClick={(e) => {
                      e.preventDefault();
                      toCareerPage();
                    }}
                  >
                    Career
                  </span>
                  <span>/</span>
                  <span>{data.position}</span>
                </p>
              </div>
              <div className="JobDescription">
                <div className="jobDescriptionTitle">
                  <span>Job descriptions</span>
                </div>
                <div className="jobDescriptionBody">
                  <ul>{showJobDescription()}</ul>
                </div>
                <div className="jobDescriptionTitle">
                  <span>Job requirements</span>
                </div>
                <div className="jobDescriptionBody">
                  <ul>{showJobRequirements()}</ul>
                </div>
              </div>
              <div className="applyNow">
                <div className="applyNowTitle">
                  <span>Apply for this job</span>
                </div>
                <div className="applyNowBody">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitResume(e);
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <span>Your name </span>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <input
                          type="text"
                          name="position"
                          value={data.position}
                          hidden
                        />
                        <input
                          type="text"
                          name="name"
                          value={applicantDetail.name || ""}
                          required
                          onChange={(e) => {
                            e.preventDefault();
                            setApplicantDetail({
                              ...applicantDetail,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <span>Your email </span>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <input
                          type="email"
                          name="email"
                          value={applicantDetail.email || ""}
                          required
                          onChange={(e) => {
                            e.preventDefault();
                            setApplicantDetail({
                              ...applicantDetail,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <span>Your phone number </span>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <input
                          type="text"
                          name="phoneNumber"
                          value={applicantDetail.phoneNumber || ""}
                          required
                          onChange={(e) => {
                            e.preventDefault();
                            setApplicantDetail({
                              ...applicantDetail,
                              phoneNumber: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <span>Your linkedlin link </span>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <input
                          type="text"
                          name="linkedlinAddress"
                          value={applicantDetail.linkedlinAddress || ""}
                          required
                          onChange={(e) => {
                            e.preventDefault();
                            setApplicantDetail({
                              ...applicantDetail,
                              linkedlinAddress: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <span>Share link of your resume </span>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <input
                          type="text"
                          name="resumeLink"
                          value={applicantDetail.resumeLink || ""}
                          placeholder="You can upload your resume at google drive and share its link here."
                          required
                          onChange={(e) => {
                            e.preventDefault();
                            setApplicantDetail({
                              ...applicantDetail,
                              resumeLink: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <span>Let's hear more about you </span>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <textarea
                          type="text"
                          name="coverLetter"
                          placeholder="Submit your cover letter"
                          value={applicantDetail.CV || ""}
                          required
                          onChange={(e) => {
                            e.preventDefault();
                            setApplicantDetail({
                              ...applicantDetail,
                              CV: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="row addFile">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <span>Upload your resume </span>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <span>Send your resume at hr@virtuosway.com</span>
                                        <button onClick={(e)=>{e.preventDefault();addResume()}}>Add file</button>
                                        
                                        <span id="fileAddedInfo"></span>
                                        <input type="file" name="resume" hidden  id="resumeUploadButton"  required onChange={(e)=>{e.preventDefault();vtCvUpload(e);}}/>
                                    </div>
                                </div> */}
                    <div className="row submitJobApplication">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <button>Submit application</button>
                      </div>
                    </div>
                  </form>
                  <div className="row send-Resume-at-email">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <span>OR , Send your resume at hr@virtuosway.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
    else{
        return <Navigate to="/career" replace />
    }
   
    
    
    
    
    
    
    
    
    
}
export default ViewJob;