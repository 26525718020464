import React from 'react';
import './view.css';
import pointOfView from '../../assests/pointOfView/pointOfView.webp';
import image6 from '../../assests/pointOfView/image6.jpeg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const View=()=>{
    const callTechnologyPage=()=>{
        window.location.href=`/technologies`
    }
    return(
        <div className="view ">
            <div className="viewPart row m-0">
                <div className="ObjsImagePart col-md-6">
                    <span>
                        <img src={"Virtuosway_00011.jpg"} alt="pointOfView" className="img-fluid"/>
                    </span>
                </div>
                <div className="Persps transformationDigital col-md-6">
            <div className="ObjsContents">
                <h2>Transformative digital technology solutions</h2>
                <div className="ObjsContentBody techContentHomepage ">
                    <p>
                        Virtuosway has extensive experience in creating high performing, digitally transformative and feature-packed native mobile applications for Android and iOS devices.
                    </p>
                    <p><span onClick={(e)=>{e.preventDefault();callTechnologyPage()}}>TECHNOLOGY<span className="FA"><FontAwesomeIcon icon={faAngleRight}/></span></span></p>
                    {/* <p>Let us deal with the overly complex, confusing, and detailed bits - and to you, we will deliver simple but trailblazing solutions to navigate the real-world challenges. 
                        Prioritizing quality over quantity, we handpick only a few products and services to develop but guarantee they are simple yet truly meaningful to the modern world. We ensure they are easy to use, backed with the latest technological advancements, and provide unmatched utility to the user.
                    </p>
                    <p><span>And how do we do so?</span></p>
                    <p>We have a team  a team of experts with decade-long, unparalleled experience under their belts and curious young talents aiming for new horizons every time. 
                        And together, they create powerful, world-class products for a myriad of industry verticals.
                    </p> */}
                </div>
            </div>

            </div>

            </div>
            
        </div>
    )
}

export default View;
