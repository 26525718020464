import React ,{useEffect} from 'react';
import Opening from './opening';
import Value from './values';
import Footer from '../footer/footer';
import Activities from './activities';
import { useLocation, useSearchParams } from 'react-router-dom';

const WholeCareerPage=()=>{
    const {search} =useLocation();
    const elementId=search?.split("?")[1];
    

    useEffect(()=>{
        const element=document.getElementById(elementId);
        !!element&&element.scrollIntoView({behavior:"smooth"})

    },[elementId])
    return(
        <div className="wholeCareerPage">
            <div id="current-openings-virtuosway"><Opening/></div>
            <div id="activities-virtuosway"><Activities/></div>
            <div ><Value/></div>
         

            {/* <div><Footer/></div> */}
            
        </div>
    )
}

export default WholeCareerPage;