import React ,{useEffect} from 'react';
import './about.css';
import AboutUsHistory from './history';
import AboutCompany from './aboutCompany';
import Workingprocess from './work';
import Mission from './missionPart';
import AboutUSObjective from './aboutUsObjective';
import Footer from '../../components/footer/footer';
 import Team from './team';
 import { useLocation, useSearchParams } from 'react-router-dom';

const AboutUS=()=>{
    const {search} =useLocation();
    const elementId=search?.split("?")[1];
    

    useEffect(()=>{
        const element=document.getElementById(elementId);
        !!element&&element.scrollIntoView({behavior:"smooth", block: 'nearest', inline: 'end'})

    },[elementId])
    return(
        <div className="aboutUSPage">
            <div className="body2">
                <div className="bodyContent10 ">
                    <div className=" bodyAboutUs">
                        <div className="bankingBodyContent aboutUsContent">
                            <div className='row'>
                                <h1>Driven By Passion, Powered By Technology</h1>
                            </div>
                            <div className="row">
                                <span>
                                    We care about who we work for and the quality of work we deliver, and the impact that makes.
                                </span>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="aboutUsBody ">
                <div className="container"><AboutUsHistory/></div>
                {/* <div><AboutCompany/></div> */}
                {/* <div className='container'><Workingprocess/></div> */}
                <div ><Mission/></div>
                {/* <div><Mission/></div>
                <div><AboutUSObjective/></div> */}
                 <div id="virtuosway-team"><Team/></div> 
                

            </div>
            {/* <div><Footer/></div> */}

        </div>
    
        )
}

export default AboutUS;