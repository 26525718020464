import React ,{useEffect}from 'react';
import './about.css';
import about15 from '../../assests/aboutus/about15.avif';
import about9 from '../../assests/aboutus/about9.jpeg';
import about3 from '../../assests/aboutus/about11.webp';
import about16 from '../../assests/aboutus/about16.avif';


const Mission=()=>{
   
    
    return(
        <div className="aboutUsMissionPart">
            <div className="aboutMissionPartContent">
                <div className="ourMission ">
                    <div className="vt-about-our-mission row" id="our-Point-Of-view">
                        <div className=" missionImage  col-lg-6">
                            <span>
                                <img src={"Virtuosway_0006.jpg"} alt="about" className='img-fluid'/>
                            </span>
                        </div>
                        <div className="missionTitle missionTitle1 col-lg-6 col-md-12">
                            <div className="row ">
                                <h2>Our point of view</h2>
                            </div>
                            <div className="row missionContents">
                                <li>We are all human</li>
                                <p>We come to work from all walks of life. We each carry a unique history, strengths, and vulnerabilities. We unreservedly respect everybody's race, gender, sexual orientation, religion, and ethnicity.</p>
                                <li>We Are All Works-In-Progress</li>
                                <p>We push the envelope to learn and evolve continuously as humans, teammates, and members of society. Therefore, we acknowledge each one of our beliefs and experiences. </p>
                                <li>We Are All Connected </li>
                                <p>Healing and bettering ourselves and our relationships with each other reverberates throughout our teams, society, and the whole world. </p>
                                <li>We Want To Make A Positive Impact In The World</li>
                                <p>We believe in creating change for the better. We build articulate works with a definite purpose, meaning, and impact on mankind. </p>
                                <li>We Are Responsible For Ourselves and Accountable To Each Other </li>
                                <p>We are both autonomous entities and team members concurrently - working together to grow and succeed every day. </p>
                                <li>We Believe In Equitable, Empowering, And Sustainable Workplace </li>
                                <p>We pull out all the stops to establish a positive workplace where every one of us feels included, empowered, valued, and safe to be their original selves. </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="ourPerspective row" id="our-beliefs-in-action">
                    <div className="missionTitle perspectiveTitle col-lg-6  ">
                        <div className="row">
                            <h2>Our Beliefs In Actions </h2>

                        </div>
                        <div className="row">
                            {/* <p className='topContentBelief'>
                                <span>
                                    The best way to illustrate our beliefs is through the choices we make and the actions we take. 
                                </span>
                            </p> */}
                                <li>Our Approach To People </li>
                                <p>
                                    We commit to empowering people to be their best selves every day. While most companies focus on churning the most out of their employees, even at the cost of their wellbeing, we create a work environment around intrinsic motivation.Beyond the idea of motivating employees with momentary rewards and punishments, we give them the freedom and flexibility to choose where and how they want to work.While others believe in employees clocking in at the workplace at predefined hours as the hallmark of a 'good' employee, we track the contributions they make and the value they leave to foster an inclusive and equitable work environment. 
                                </p>
                                <li>Our Approach To Teams</li>
                                <p>
                                    We don't intend to blow our own horns, but we are among the companies with the lowest levels of bureaucracy in the industry. As a matter of fact, we've gotten rid of it wherever and whenever we could.Instead, we thrive on humility and respectful boundaries.We don't look to snarl our team members with inflexible, impractical, and outdated policies. Instead, we weave inclusion and diversity into the very fabric of our company.
                                </p>
                                <li>Our Approach To Make This World A Better Place</li>
                                <p>
                                    We intend on providing the highest values for our clients. While some may prioritize earning as much revenue as possible from customers, we focus on creating a better world and building relationships that last a lifetime.Going beyond the practice of merely complying with regulations and protecting brand image at all costs, we take full responsibility to improve the social and environmental conditions for our team, clients, and the community. 
                                </p>
                           
                        

                        </div>

                    </div>
                    <div className="col-lg-6 ">
                        <div className="row perspImage ">
                            <span>
                                <img src={"Virtuosway_0004.jpg"} className='img-fluid'/>
                            </span>
                        </div>

                    </div>

                </div>
                <div className="ourMission ourValuesAboutUs row" id="our-values">
                    <div className=" missionImage  col-sm-6 ">
                        <span>
                            <img src={"Virtuosway_13.jpg"} alt="about" className='img-fluid'/>
                        </span>
                    </div>
                    <div className="missionTitle col-sm-6 ">
                        <div className="row ">
                            <h2>Our Values</h2>
                        </div>
                        <div className='row rowOurValues'>
                            <p>
                                <span>
                                    Virtuousway Inspires People To Lead Better Lives Everyday 
                                </span>
                            </p>
                            <p>
                                Virtuousway is a human-centered organization passionately motivated by its vision to help people live better lives. Every decision we make is underpinned by our promise to provide the best value for our clients and do right by our team members. 
                            </p>

                        </div>
                        
                    </div>

                </div>
                {/* <div className="ourPerspective row">
                    <div className="missionTitle perspectiveTitle col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="row">
                            <h2>Our objectives</h2>

                        </div>
                        <div className="row">
                            <p><span>Our Ethos: Innovation With Simplicity</span></p>
                            <p>
                                At Virtuosway, we believe in transforming the complexities of coding and programming into simple solutions that empower everybody everywhere to innovate. 
                                Let us deal with the overly complex, confusing, and detailed bits - and to you, we will deliver simple but trailblazing solutions to navigate the real-world challenges.And how do we do so?
                                We have a team - a team of experts with decade-long, unparalleled experience under their belts and curious young talents aiming for new horizons every time. 
                                And together, they create powerful, world-class products for a myriad of industry verticals. 
                            </p>
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="row perspImage objsImage ">
                            <span>
                                <img src={about16}/>
                            </span>
                        </div>

                    </div>

                </div> */}

            </div>
        
        </div>
    )
}
export default Mission