
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageNotFound from './components/pageNotFound/pageNotFound';
import RoutesInformations from "./RoutesInformations.js";


function App() {
  
  return (
    <Router>
      {/* <Routes>
        <Route   path="/" element={<RoutesInformations />} />
        <Route  path="*" element={<PageNotFound />} />
        
         
      </Routes> */}
      <RoutesInformations />
    </Router>
  );
}

export default App;
