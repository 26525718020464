import React from 'react';
import './client.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ClientCard from './clientCard';
// import client1 from '../../assests/client/client1.jpeg';
// import client2 from '../../assests/clinet/client2.jpeg'
//import worldmap from '../../assests/client/worldmap.png';
//import company1 from '../../assests/client/logos/company1.jpeg';
//import company2 from '../../assests/client/logos/company2.png';
//import company3 from '../../assests/client/logos/company3.png';
//import company4 from '../../assests/client/logos/company4.png';
// import group from '../../assests/testinomials/group.webp'


const Client=()=>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1
        }
      };
      const data=[
          {image:"client1.jpeg",review:"Virtuosway has been an ideal partner for our digital transformation.They take extreme care to understand our complex business processes  and challenges.Virtuosway translates what we are building into plan and it is a team that constantly and relentlessly delivers.",
          name:"Gregory Sukornyk",position:"Chief Executive Officer at Global Study,",address:"Toronto, Ontario, Canada"},
          {image:"client2.jpeg",review:"Virtuosway has been a true partner from our company’s inception. Their team went above and beyond to understand our needs and executed our first MVP. We look forward to many more projects together.",
          name:"Jonathan Marcoschamer",position:"Founder at OpenSeed,",address:"Miami/Fort Lauderdale Area"},
          {image:"client3.png",review:"Virtuosway's hardworking team effectively handled the complications and showed flexibility when asked to rebuild parts of the project",name:"James Termini,PhD",position:"Managing Partner",address:"Tangent Inc,USA"},
          {image:"client4.png",review:"Virtuosway has been an essential part of our strategy. We have partnered with this company from their early days. We are tremendously excited by the results the team delivered. The team is incredibly efficient, immensely helpful and thoroughly professional. They are experts in their subject matter. I am extremely glad to be working with them.",name:"Niraj Shrestha,PhD",position:"Managing Partner/Co-Founder",address:"Eepos IT Holdings,USA"}

         
      ]

    return(
        <div className="client">
            <div className="clientContent">
                <div className="clientContentTopic">
                    <h2>Satisfied clients worldwide</h2>
                </div>
                <div className="clientContent">
                    <Carousel responsive={responsive} >
                        <ClientCard>{data[0]}</ClientCard>
                        <ClientCard>{data[1]}</ClientCard>
                        <ClientCard>{data[2]}</ClientCard>
                        <ClientCard>{data[3]}</ClientCard>
                        
                    </Carousel>

                </div>
                
            </div>

        </div>












        // <div className="Client">
        //     <div className="clientContent">
        //         <div className="row">
        //             <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
        //             <div className="col-lg-4 col-md-4 col">
        //                 <h2>Our clients</h2>
        //             </div>
        //             <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
        //             <div className="col-lg-6 col-md-6 col">
        //                 <p>With offices in Nepal and the United States, we've worked with over 123 leading brands to inspire, guide, and accompany them on their innovation journey. </p>
        //             </div>
        //         </div>
        //         <div className="clientContentBody">
        //             <div className="worldMap">
        //                 <img src={worldmap} alt="world map"/>
        //             </div>
        //         </div>
        //     </div>
            /* <div className="clientLogo">
                <div className="clientLogoContent">
                    <h3>Our Hall Of Fame</h3>
                    <div className="logos">
                        <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <img src={company1} alt="company1"/>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <img src={company2}alt="company2" />
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <img src={company3} alt="company3"/>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <img src={company4} alt="company4"/>
                            </div>
                        </div>
                    </div>
                    <div className="testinomial">
                        <span><img src={group} alt="groupDiscussion"/></span>

                    </div>
                    
                </div>

                </div> */
            
        // </div>
    )
}

export default Client;