import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/header";
import "./homepage/homepage.css";
import AboutUS from "./components/aboutUs/about";
import Homepage from "./homepage/homepage";
import TechnologyPage from "./components/Technology/technologyPage";
import Footer from "./components/footer/footer";
import IndustryPage from "./components/industries/fullpageIndustry/industry";
import Solution from "./components/solutions/wholeSolutionPage/solution";
import WholeCareerPage from "./components/career/wholeCareerPgae";
import ViewJob from "./components/career/viewJob";
import LoginPage from "./components/adminPage/login";
import AdminDashboard from "./components/adminPage/adminDashboard";
import CareerPage from "./components/career/careerPage1";
import PrivacyPolicy from "./components/policy/privacyPolicy";
import TermAndUse from "./components/policy/terms";
import PageNotFound from "./components/pageNotFound/pageNotFound";

const RoutesInformations = () => {
  const [hideHeader, setHideHeader] = useState(false);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
  useEffect(() => {
    let pathNow = window.location.pathname;
    if (pathNow === "/admin-login-page" || pathNow === "/admin-dashboard") {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
  }, []);

  return (
    <div>
      {!hideHeaderFooter && (
        <div
          className={hideHeader ? "hideHeader" : " showHeader headerComponent"}
        >
          <Header />
        </div>
      )}

      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path="/industries" element={<IndustryPage />}></Route>
        <Route exact path="/solution" element={<Solution />}></Route>
        <Route exact path="/career/job-detail" element={<ViewJob />}></Route>
        <Route exact path="/about-us" element={<AboutUS />} />
        <Route exact path="/technologies" element={<TechnologyPage />} />
        <Route exact path="/career" element={<CareerPage />} />
        <Route
          exact
          path="/career/current-openings"
          element={<WholeCareerPage />}
        />
        <Route exact path="/admin-login-page" element={<LoginPage />} />
        <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/TermAndUse" element={<TermAndUse />} />

        <Route
          path="*"
          element={
            <PageNotFound onLoad={(value) => setHideHeaderFooter(value)} />
          }
        />
      </Routes>

      {!hideHeaderFooter && (
        <div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default RoutesInformations;
