import React from 'react'
import './about.css';
import image1 from '../../assests/aboutus/image1.webp';
import Nabinsir from '../../assests/aboutus/Nabinsir.jpg';
import binodsir from '../../assests/aboutus/binodsir.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ganeshsir from '../../assests/aboutus/ganeshsir.jpg'



const Team=()=>{
    return(
        <div className="team" >
            <div className="teamTitle">
                <h2>Our team</h2>
            </div>
            <div>
                <div className="teamMembers row">
                    {/* <div className="col-lg-1 col-md-0 col-sm-0 col-xs-1 "></div> */}
                    <div className="col-lg-2 col-md-12 "></div>
                    <div className="col-lg-4 col-md-12 ">
                        <div className="card" >
                            <span>
                                <img className="card-img-top" src={Nabinsir} alt="Card image cap" />
                            </span>
                            <div className="card-body">
                                <h5 className="card-title">Nabin Shrestha</h5>
                                <span className="designation">Managing Director</span>
                                <p className="card-text">Nabin has over 8+ years’ experience in software development and 15+ years in design and communication. He oversee overall business and operations. He has worked with over 300+ clients on various verticals, including aviation, automobiles, tourism and hospitality, banking and finance, health, real state, pharmaceutical, agriculture, and over 50+ social sector clients. Nabin received his Bachelor’s degree in Design Communication.</p>
                                
                                <p className="linkedlinAccount">
                                    {/* <span>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </span> */}
                                </p>
                                {/* <a href="/" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-12 ">
                        <div className="card" >
                            <div >
                                <span>
                                    <img className="card-img-top" src={binodsir} alt="Card image cap" />
                                </span>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Binod Karki</h5>
                                <span className="designation">Executive Director-Technology</span>
                                <p className="card-text">
                                    Binod has over 12+ years of experience in IT industry.He has delivered high quality multiple products across the globe which are used by millions of users.He is experienced campaigner in dealing with clients ,customer interactions, marketing and product management.He has worked with diverse cross functional teams in different countries ( USA, China, India and Nepal ).

 

                                </p>
                                <p className="linkedlinAccount">
                                    {/* <span>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </span> */}
                                </p>                          
                                </div>
                            </div>

                    </div>
                    {/* <div className="col-lg-4 col-md-12 ">
                        <div className="card" >
                            <span>
                                <img className="card-img-top" src={ganeshsir} alt="Card image cap" />

                            </span>
                            
                            <div className="card-body">
                                <h5 className="card-title">Ganesh Regmi</h5>
                                <span className="designation">Project Manager</span>
                                <p className="card-text">Over 9 years of software industry experience in different roles as programmer,  project manager, product owner and founder of a startup, Ganesh has worked in diverse business spectrum related to big data, e-governance, digital wallet and various web, mobile and data applications for industries across the globe. He is hardworking young professional with a strong analytical mind and problem-solving skills.</p>
                                <p className="linkedlinAccount">
                                    <span>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </span>
                                </p>                          
                                 </div>
                        </div>

                    </div> */}
                    {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 "></div> */}
                </div>
                {/* <div className="teamMembers row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 "></div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="card" >
                            <span>
                                <img className="card-img-top" src={image1} alt="Card image cap" />

                            </span>
                            
                            <div className="card-body">
                                <h5 className="card-title">Card title</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <p className="linkedlinAccount">
                                    <span>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </span>
                                </p>                           </div>
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="card" >
                            <span>
                                <img className="card-img-top" src={image1} alt="Card image cap" />
                            </span>
                            
                            <div className="card-body">
                                <h5 className="card-title">Card title</h5>
                                <p className="card-tetxt">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <p className="linkedlinAccount">
                                    <span>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </span>
                                </p>                           
                            </div>
                        </div>

                    </div>
                </div> */}
            </div>
        </div>
    )
}
export default Team;