import React,{useState} from 'react';
import './admin.css';

const LoginPage=()=>{
    const[email,setEmail]=useState();
    const[password,setPassword]=useState();
    const loginAdmin=()=>{
        if(email==="hr@virtuosway.com" && password==="Virtuosway@12345"){
            localStorage.setItem('login',true)
            window.location.href="/admin-dashboard"
        }
        else{
            alert("Not authenticated")
        }
    }

   

    return(
        <div className='loginpage'>
            <div className='contentLoginPage'>
                <div className="loginTitle">
                    <h2>Login Page</h2>
                </div>
                <div className="loginBody">
                    <form>
                        {/* <label>Your email :</label> */}
                        <br/>
                        <input type="email" name="email" placeholder='email' value={email || ""} onChange={(e)=>{e.preventDefault();setEmail(e.target.value)}}/>
                        <br/>
                        {/* <label>Your password :</label> */}
                        <br/>
                        <input type="password" name="password" placeholder='password' value={password || ""} onChange={(e)=>{e.preventDefault();setPassword(e.target.value)}}/>
                        <br/>
                        <p>
                            <span>
                                <button onClick={(e)=>{e.preventDefault();loginAdmin()}}>Submit</button>
                            </span>
                        </p>
                        
                    </form>

                </div>


            </div>

        </div>
    )
}
export default LoginPage;
