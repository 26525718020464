import React from 'react';
import './objectives.css';
//import objectives from '../../assests/objs/objectives.webp';
//import image8 from '../../assests/objs/image8.webp';
//mport image6 from '../../assests/objs/image6.webp'
import image10 from '../../assests/objs/image10.webp'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";


const Objs=()=>{
    const callIndustryPage=()=>{
        window.location.href=`/industries`
    }
    return(
        <div className="Objs row m-0">
            <div className="ObjsImagePart col-md-6">
                <span>
                    <img    src={"Virtuosway_0007.jpg"} alt="objsimage" className="img-fluid"/>
                </span>

            </div>
            <div className="objectiveBody col-md-6">
            <div className="ObjsContents">
                <h2>Opportunity is everywhere</h2>
                <div className="ObjsContentBody">
                    <p>
                        We ensure the highest levels of certainity and satisfaction throough a deep-set commitment to our clients,
                        comprehensive industry expertise, and innovation and delivery.
                    </p>
                    <p><span onClick={(e)=>{e.preventDefault();callIndustryPage()}}>INDUSTRIES<span className="FA"><FontAwesomeIcon icon={faAngleRight}/></span></span></p>
                    {/* <p>Let us deal with the overly complex, confusing, and detailed bits - and to you, we will deliver simple but trailblazing solutions to navigate the real-world challenges. 
                        Prioritizing quality over quantity, we handpick only a few products and services to develop but guarantee they are simple yet truly meaningful to the modern world. We ensure they are easy to use, backed with the latest technological advancements, and provide unmatched utility to the user.
                    </p>
                    <p><span>And how do we do so?</span></p>
                    <p>We have a team  a team of experts with decade-long, unparalleled experience under their belts and curious young talents aiming for new horizons every time. 
                        And together, they create powerful, world-class products for a myriad of industry verticals.
                    </p> */}
                </div>
            </div>

            </div>
            
           
        </div>
    )
    
}
export default Objs;