import React from 'react';
import about2 from '../../assests/aboutus/about2.webp';
import './about.css'

const AboutUsHistory=()=>{
    return(
        <div className="aboutUsHistory ">
            <div className="history">
                <div className="imageFieldAbout">
                    <span>
                        <img src={about2} alt="groupPhoto"/>
                    </span>
                </div>
                <div className="aboutUsHistoryParagraph">
                    <div>
                        <h2>About Company</h2>
                    </div>
                    <div className="aboutUsTopPart">
                        <div>
                            <p className="aboutUsPart1">
                            At Virtuosway, we blend together technical advances, specific domain experience, and a sheer passion for excellence to create enterprise-grade solutions for organizations of all levels and caliber - from SMEs to global enterprises.We create digital solutions reinforced with innovation, a strong management philosophy, unfaltering customer relationships, and often, a leap of faith.With years of experience in creating the systems and managing the workings of companies across different industry verticals globally, our team of experts will help your organization navigate its digital journey the way it deserves. 
                            </p>

                        </div>
                        {/* <div>
                            <p>
                                Virtuosway offices span America and Nepal - offering continuous delivery wherever our customers happen to be. Our services are led by passionate teams pushing boundaries and challenging the limits every day to provide web and mobile solutions equipped with the best industry practices and technological know-how to lead the digital transformation. 
                                We are a collective of experts and creatives from multiple backgrounds that work together, bound by our endless love for software, innovation, and technology.

                            </p>
                        </div>
                        <div>
                            <p className="aboutUsPart1">
                                And for us, good isn't good enough. We strive to be the best at all that we do - to help our clients realize their ambitions, maximize success, and make a positive difference in society. 
                                Our technical prowess and decades of experience allow us to focus on creating real value through digital transformation. We bring together our knowledge and expertise in the latest and emerging technologies to help companies navigate through the dynamic challenges of the 21st century. 
                                <span>If you want to talk tech for good, leave us a message. Ideas, challenges, questions.We'd love to talk! </span>
                            </p>
                        </div>
                         */}
                        
                        
                        
                    </div>
                    {/* <p>
                        <span>If you want to talk tech for good, leave us a message. Ideas, challenges, questions.We'd love to talk! </span>
                    </p> */}

                </div>

            </div>

        </div>
    )
}
export default AboutUsHistory;