import React, { useEffect, useLayoutEffect } from 'react';
import './pageNotFound.css';



export default function PageNotFound(props) {

    useLayoutEffect(() => {
      props?.onLoad(true)
    
      return () => {
        props?.onLoad(false)
      }
    }, [])
    
  return (
    <div className="page-not-found">
      <span>Page Not Found</span>
    </div>
  );
}