import React from 'react';
import './homepage.css';
import Body from '../components/body/body';
import Objs from '../components/objectives/objectives';
import Perspective from '../components/perspective/persp';
import Technology from '../components/Technology/technology';
import Client from '../components/client/client';
import Footer from '../components/footer/footer';
import View from '../components/pointOfView/view.js';
import WhyUs from '../components/whyChooseUs';
import KnowMore from '../components/knowAboutus/knowabout';
// import Header from '../components/Header/header';
import Portfolio from '../components/portfolio/portfolio';
import VTEquation from '../components/VTEquation/equation';

const Homepage=()=>{
    return(
        <div className="mainHomePage" >
            {/* <div className="headerComponent"><Header/></div> */}
            <div ><Body/></div> 
            <div ><Objs/></div>
            <div><Perspective/></div>
            <div><View/></div>
            <div><Portfolio/></div>
            <div><Client/></div>
            <div><VTEquation/></div>
            {/* <div><Technology/></div>
             
            <div><WhyUs/></div>
            <div><KnowMore/></div> */}
            {/* <div><Footer/></div>  */}
            
        </div>
    )
}

export default Homepage;