import React from 'react';
import './client.css';

const ClientCard=(props)=>{
    let propImg=props.children.image
    return(
        <div className="clientCardContent">
            <div className="clientCardImage">
                <img src={require(`../../assests/client/${propImg}`)} alt="clientCardImage"/>
            </div>
            <div className='clientCardContent'> 
                <div className="clientReview row">
                    <p className="review">{props.children.review}</p>
                    <p className="reviewerName">
                        <span>{props.children.name}</span>
                        <br/>
                        <span>{props.children.position}</span>
                        <br/>
                        <span>{props.children.address}</span>
                    </p>
                    
                </div>
                <div className="clientBackground row">
                    {/* <p>{props.children.name}</p>
                    <p>{props.children.position}</p>
                    <p>{props.children.address}</p> */}

                </div>

            </div>

            
        </div>
    )
}
export default ClientCard