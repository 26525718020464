import React from 'react';
import './equation.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from 'react-router-hash-link';



const VTEquation=()=>{
    const CurrentOpening=()=>{
        window.location.href='/career/current-openings';
    }
    const callOurPointOfView=()=>{
        window.location.href=`/about-us?our-Point-Of-view`;

    }
    return(
        <div className="VTEquation">
            <div className="VTEquationContents">
                <div className="VTequationtitle">
                    <h2>Virtuosway Equation</h2>
                </div>
                <div className="VTEquationBody">
                    <span onClick={callOurPointOfView}>Our Point of View</span><span><FontAwesomeIcon icon={faAngleRight}/></span>
                    <span><a href="/about-us?our-beliefs-in-action">Our Beliefs In Action</a></span><span><FontAwesomeIcon icon={faAngleRight}/></span>
                    <span class="vt-eqn-career" onClick={(e)=>{e.preventDefault();CurrentOpening()}}>CAREER</span>
                </div>
            </div>
        </div>
    )
}

export default VTEquation;